import React,{Component} from 'react';

class Info extends Component {

    constructor(props) {

        super(props);

        this.state = {

        };

    }

    render() {

        return (

            <div>
                <h1>Info</h1>
            </div>

        )

    }
}

export default Info;

