import moment from 'moment'

class DateFormat {
    static date(data) {
        return moment(data).format('MM-DD-Y')
    }

    static dateTime(data) {
        return moment(data).format('MM-DD-Y HH:mm:ss')
    }
    static fullDateTime(data) {
        return this.dayOfWeek(data)+" "+ moment(data).format('MMM DD, YYYY hh:mm:ss a')
    }
    
    static dayOfWeek(date){
        let days=['Sunday',
        'Monday',
        'Tuesday',
        'Wednesday',
        'Thursday',
        'Friday',
        'Saturday',
        ];
        let numDay = new Date(date).getDay();
        let nameDay = days[numDay];
        return nameDay;

    }

    static amount(data) {
        return data.toFixed(2);
    }

    static urlQuery(params) {
        let url = '?'
        Object.keys(params).map(function (index) {
            url = url + "&" + index + "=" + params[index]
            return url
        })
        return url
    }
}

export default DateFormat;