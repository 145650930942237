import React, {Component} from "react";
import Table from "@material-ui/core/Table";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import TableCell from "@material-ui/core/TableCell";
import Api from "../../../libraries/api";
import TableBody from "@material-ui/core/TableBody";
import TextField from "@material-ui/core/TextField";
import {toast} from "react-toastify";
import CircularProgress from "@material-ui/core/CircularProgress";
import MenuItem from "@material-ui/core/MenuItem";

export default class TransRules extends Component {
    constructor(props) {
        super(props);
        this.state = {
            loading: true,
            rows: [],
            id: '',
            product: '',
            transLimit: 0,
            showForm: false,
        };
        this.getTransRules();
        this.handleTransDetail = this.handleTransDetail.bind(this);
        this.handleChange = this.handleChange.bind(this);
        this.handleUpdateTrans = this.handleUpdateTrans.bind(this);
    }

    handleTransDetail(id) {
        let transDetail = "/payment-rules/id/" + id;
        Api.get(transDetail, [], false).then((resp) => {
            this.setState({
                showForm: true,
                id: resp["data"]["paymentRules"]["id"],
                product: resp["data"]["paymentRules"]["product"],
                status: resp["data"]["paymentRules"]["status"],
                transLimit: resp["data"]["paymentRules"]["transactionLimit"],
            })
        });
    }

    handleUpdateTrans() {
        const updateTransRuleUrl = "/payment-rules/id/" + this.state.id;
        let parameters = {
            product: this.state.product,
            fee: 0,
            feeAvg: 0,
            transactionLimit: this.state.transLimit,
            status: this.state.status
        };
        Api.put(updateTransRuleUrl, parameters, false).then((resp) => {
            this.getTransRules()
            toast.success(resp.messages, {
                position: toast.POSITION.BOTTOM_CENTER,
            })
        }).catch(err => {
                toast.error(err.errors.messages, {
                    position: toast.POSITION.BOTTOM_CENTER,
                });
            }
        );
    }

    handleChange(e, prop) {
        this.setState({
            [prop]: e.target.value
        })
    };

    getTransRules() {
        let paymentRulesConfigUrl = "/payment-rules?ruleType=transaction";
        Api.get(paymentRulesConfigUrl, [], false).then((resp) => {
            this.setState({
                rows: resp["data"]["paymentRules"],
                loading: false,
                showForm: false,
            });
        });
    }

    render() {
        return (
            <div className="">
                <div className="card col-12">
                    <TransRulesDetail showForm={this.state.showForm}
                                      handleChange={this.handleChange}
                                      handleUpdateTrans={this.handleUpdateTrans}
                                      id={this.state.id}
                                      product={this.state.product}
                                      transLimit={this.state.transLimit}
                                      status={this.state.status}/>
                    <div className="table-wrapper">
                        <Table className="table-list mt-3" size="small">
                            <TableHead>
                                <TableRow>
                                    <TableCell>Product</TableCell>
                                    <TableCell>Alias</TableCell>
                                    <TableCell>Transaction limit</TableCell>
                                    <TableCell>Status</TableCell>
                                </TableRow>
                            </TableHead>
                            {this.state.loading ?
                                (
                                    <TableBody>
                                        <TableRow>
                                            <TableCell colSpan={8} align="center" className="py-5">
                                                <CircularProgress color="primary"/>
                                            </TableCell>
                                        </TableRow>
                                    </TableBody>
                                ) :
                                (
                                    <TransRulesTable rows={this.state.rows} handleTransDetail={this.handleTransDetail}/>
                                )
                            }
                        </Table>
                    </div>
                </div>
            </div>
        )
    }
}

class TransRulesTable extends Component {

    render() {
        let handleTransDetail = this.props.handleTransDetail;
        return (
            <TableBody>
                {this.props.rows.map(function (item, i) {
                    return (
                        <TableRow key={i} onClick={() => handleTransDetail(item.id)}>
                            <TableCell>{item.product}</TableCell>
                            <TableCell>{item.alias}</TableCell>
                            <TableCell>{item.transactionLimit}</TableCell>
                            <TableCell>
                                {item.status === 1 ? 'Enable' : 'Disable'}
                            </TableCell>
                        </TableRow>
                    )
                })}
            </TableBody>
        )
    }
}

class TransRulesDetail extends Component {
    render() {
        if (this.props.showForm === true) {
            let handleChange = this.props.handleChange;
            let handleUpdateTrans = this.props.handleUpdateTrans;
            return (
                <div className="MuiPaper-root MuiPaper-elevation1 show MuiPaper-rounded">
                    <div>
                        <div className="form-group row">
                            <div className="col-sm-3 pl-sm-0">
                                <TextField type='text' name="product" id="product"
                                           label="Product"
                                           value={this.props.product}
                                           onChange={(e) => handleChange(e, 'product')}/>
                            </div>
                            <div className="col-sm-3 pl-sm-0">
                                <TextField type='text' name="transactionLimit" id="status"
                                           label="Limit"
                                           defaultValue={this.props.transLimit}
                                           onChange={(e) => handleChange(e, 'transLimit')}/>
                            </div>
                            <div className="col-sm-3 pl-sm-0">
                                <TextField
                                    select
                                    name="status" id="status"
                                    label="Status"
                                    value={this.props.status}
                                    onChange={(e) => handleChange(e, 'status')}>
                                    <MenuItem key={1} value={1}>
                                        Enable
                                    </MenuItem>
                                    <MenuItem key={0} value={0}>
                                        Disable
                                    </MenuItem>
                                </TextField>
                            </div>
                        </div>
                        <div className="form-group row">
                            <button
                                className="btn btn-blue-trans"
                                onClick={() => handleUpdateTrans()}>Save
                            </button>
                        </div>
                    </div>
                    <br/>
                </div>
            )
        }
        return false
    }
}