import React, {Component} from "react";
import {toast} from "react-toastify";
import Api from "../../../libraries/api";

export default class UpdateAccount extends Component {
    constructor(props) {
        super(props);
        this.state = {
            updateAction: false,
        };
        this.handleClick = this.handleClick.bind(this);
        this.saveAccount = this.saveAccount.bind(this);
    }

    handleClick() {
        this.setState({updateAction: true})
    }

    saveAccount(e) {
        e.preventDefault()
        const updateAccountUrl = "/users/" + this.props.userId;
        let parameters = {
            status: document.getElementById("accountStatus").value,
            accountVersion: document.getElementById("accountVersion").value,
            credits: document.getElementById("credits").value,
            fullName: this.props.fullName === ''? 'test 1' : this.props.fullName
        };
        Api.put(updateAccountUrl, parameters, false).then((resp) => {
            this.setState({
                status: parameters["status"],
                accountVersion: parameters["accountVersion"],
                credits: parameters["credits"],
            })
            toast.success(resp.messages, {
                position: toast.POSITION.BOTTOM_CENTER,
            })
        }).catch(err => {
                let i;
                let errorMsg = "";
                console.log(err)
                const erroList = Object.keys(err.errors);
                for (i = 0; i < erroList.length; i++) {
                    const index = erroList[i].toString();
                    errorMsg = errorMsg + err.errors[index]
                }
                toast.error(errorMsg, {
                    position: toast.POSITION.BOTTOM_CENTER,
                })
            }
        );
        this.setState({updateAction: false})
    }

    render() {
        let accountVersion = (this.state.accountVersion == null) ? this.props.accountVersion : this.state.accountVersion
        let status = (this.state.status == null) ? this.props.accountStatus : this.state.status
        let credits = (this.state.credits == null) ? this.props.credits : this.state.credits
        if (this.state.updateAction === true) {//Update user account
            return <div>
                <form
                    id="frmUpdateAccount"
                    name="frmUpdateAccount"
                    method="Post"
                    onSubmit={this.saveAccount}
                >
                    <div className="row">
                        <label className="col-4 text-black bold ">Status</label>
                        <p className="col-8 pl-2 pl-lg-3 capitalize-text">
                            <AccountStatusItems statusItem={status}/>
                        </p>
                    </div>
                    <div className="row">
                        <label className="col-4 text-black bold ">Account version</label>
                        <p className="col-8 pl-2 pl-lg-3 capitalize-text">
                            <AccountVersionItems accountVersionItem={accountVersion}/>
                        </p>
                    </div>
                    <div className="row">
                        <label className="col-4 text-black bold ">Credits</label>
                        <p className="col-8 pl-2 pl-lg-3 capitalize-text">
                            <CreditsItems creditsItem={credits}/>
                        </p>
                    </div>
                    <input type="submit" value="Update account" className="btn btn-blue-trans mb-2"/>
                </form>
            </div>;
        } else {//Show user account information
            return <div>
                <div className="row">
                    <label className="col-4 text-black bold ">Status</label>
                    <p className="col-8 pl-2 pl-lg-3 capitalize-text">
                        {status}
                    </p>
                </div>
                <div className="row">
                    <label className="col-4 text-black bold ">Account version</label>
                    <p className="pl-2 pl-lg-3 capitalize-text">
                        {accountVersion}
                    </p>
                </div>
                <div className="row">
                <label className="col-4 text-black bold ">Credits</label>
                    <p className="pl-2 pl-lg-3 capitalize-text">
                        {credits}
                    </p>
                </div>
                <hr style={{margin:'0px'}}/>
                <button onClick={this.handleClick} className="btn btn-blue-trans mb-2" style={{marginTop:'15px'}}>Update account</button>
            </div>;
        }

    }
}

class AccountVersionItems extends Component {
    accountVersionData = [
        {value: 'lite', name: 'Lite'},
        {value: 'premium', name: 'Premium'}
    ];

    render() {
        return <select id="accountVersion" name="accountVersion" defaultValue={this.props.accountVersionItem}>
            {this.accountVersionData.map((e, key) => {
                return <option key={key} value={e.value}>{e.name}</option>;
            })}
        </select>
    }
}


class CreditsItems extends Component {
    render() {
        return <input id="credits" name="credits" type='text' defaultValue={this.props.creditsItem}/>
    }

}


class AccountStatusItems extends Component {
    accountStatusData = [
        {value: 'active', name: 'Active'},
        {value: 'suspend', name: 'Suspend'},
        {value: 'disable', name: 'Disable'}
    ];

    render() {
        return <select id="accountStatus" name="accountStatus" defaultValue={this.props.statusItem}>
            {this.accountStatusData.map((e, key) => {
                return <option key={key} value={e.value}>{e.name}</option>;
            })}
        </select>
    }

}