
import React, { Component } from 'react'
import DateFormat from '../../../libraries/data-format-helper';
import { CSVLink } from 'react-csv';
import Api from '../../../libraries/api';
import { CircularProgress, Table, TableBody, TableCell, TableHead, TablePagination, TableRow } from '@material-ui/core';
import TablePaginationActions from '@material-ui/core/TablePagination/TablePaginationActions';

export default class Vehicles extends Component {
  
    constructor(props) {
    super(props);
    this.state = {
        activeTab: 0,
        file: null,
        fileBase64: "",
        loading: false,
        errors: "",
        showUpload:false,
        vehicleList:[{
            LicensePlateState:'',
            LicensePlateCountry:'',
            LicensePlateType:'',
            ePermit:'',
            LicensePlateNumber:'',
            VehicleMake:'',
            VehicleModel:'',
            VehicleYear:'',
            VehicleColor:'',
            NickName:'',
            StartDate:'',
            StartTime:'',
            EndDate:'',
            EndTime:'',    
        }],
        nameCSV:'',
        dataCSV:[],
        rows:[],
        perPage: 50,
        currentPage: 0,
        currentPageTable: 0,
        totalData: 0,
    };
    this.csvLink = React.createRef();
    this.handleChange = this.handleChange.bind(this)
    this.getVehicles();
  }

  handleChange(e, prop) {
      this.setState({
          [prop]: e.target.value
      })
  };

  handleShowUpload(){
    this.setState({
        showUpload: !this.state.showUpload
    })
  }

    getVehicles(){
        Api.get("/vehicle/getVehicles").then(resp=>{
            const list = Object.values(resp)
            const vehicles =[]
            for(let i=0;i<list.length;i++){
                vehicles.push({
                    LicensePlateState:list[i].licensePlateState,
                    LicensePlateCountry:list[i].licencePlateCountry,
                    LicensePlateType:list[i].licencePlateType,
                    ePermit:'',
                    LicensePlateNumber:list[i].licensePlateNumber,
                    VehicleMake:list[i].make === null || list[i].make === undefined ?'':(list[i].make +"").toUpperCase(),
                    VehicleModel:list[i].type === null || list[i].type === undefined ?'':(list[i].type +"").toUpperCase(),
                    VehicleYear: list[i].year === null || list[i].year === undefined ?'':(list[i].year +""),
                    VehicleColor:list[i].color === null || list[i].color === undefined ?'':(list[i].color +"").toUpperCase(),
                    Nickname:list[i].nickname,
                    StartDate:'',
                    StartTime:'',
                    EndDate:'',
                    EndTime:'',  
                })
            }
            this.setState({
                rows:vehicles,
                dataCSV:vehicles,
                totalData:vehicles.length
            })
        }).catch(err=>{
            console.log("error=>",err)
        })
    }

    handleChangePage = (event, newPage) => {
        this.setState({
            currentPage: newPage,
            currentPageTable: newPage,
        });
    };

    handleChangeRowsPerPage = event => {
        this.setState({
            perPage: event.target.value,
            currentPage: 1,
        });
    };

    setCSVList(){
        const vehicles =[]
        let list = this.state.rows
        for(let i = 0; i<list.length-1; i++){
            vehicles.push({
                LicensePlateState:list[i].LicensePlateState,
                LicensePlateCountry:list[i].LicensePlateCountry,
                LicensePlateType:list[i].LicensePlateType,
                ePermit:'',
                LicensePlateNumber:list[i].LicensePlateNumber,
                VehicleMake:list[i].VehicleMake === ''? 'VADO' : (list[i].VehicleMake +"").toUpperCase(),
                VehicleModel:list[i].VehicleModel ===''? 'VADO' : (list[i].VehicleModel +"").toUpperCase(),
                VehicleYear: list[i].VehicleYear === '' ? '2000' : (list[i].VehicleYear +""),
                VehicleColor:list[i].VehicleColor === '' ? 'VADO' : (list[i].VehicleColor +"").toUpperCase(),
                Nickname:list[i].Nickname,
                StartDate:'',
                StartTime:'',
                EndDate:'',
                EndTime:'',  
            })
        }
        this.setState({
            dataCSV:vehicles
        })
    }

    handleDownloadCSV (){
        this.setNameCSV();
        this.setCSVList()
        setTimeout(() => { this.DownloadCSV()}, 200);
    }

    DownloadCSV(){
        this.setState({
            downloading:false
        },()=>{
            setTimeout(() => { 
                this.csvLink.current.link.click()
            })
        })
    }

    setNameCSV(){
    var currentTime = Date.now();
    var date = DateFormat.dateTime(currentTime)
    var name = 'VehicleList-'+date+'.csv'
    this.setState({nameCSV:name})
    }

  render() {
    return (
        <div className="row" >
            <div className="col-12" >
                <div className="col-md-6">
                    <h2 className='text-title-big' style={{marginBottom:'20px'}}>Vehicles List</h2>
                </div>
                <div >
                    <div className='card'>
                        <div className="row">
                             <div className="col-6" style={{paddingBottom:'10px'}}>
                                <button  className="btn btn-blue" style={{marginTop:'15px'}} 
                                        onClick={()=>this.handleDownloadCSV()}
                                        > 
                                        { this.state.downloading ?<i className="fa fa-spinner fa-spin"/> : ('') } &nbsp;
                                        <i class="fas fa-download" style={{marginRight:'3px'}}/>
                                        &nbsp;Download Vehicle List
                                </button>
                                <CSVLink 
                                    data={this.state.dataCSV}
                                    target="_blank"
                                    filename={this.state.nameCSV}
                                    ref={this.csvLink}
                                />
                            </div>
                        </div>
                        <div className="table-wrapper">
                        <Table className="table-list mt-3" size="small">
                            <TableHead>
                                <TableRow>
                                    <TableCell>State</TableCell>
                                    <TableCell>Country</TableCell>
                                    <TableCell>Plate Number</TableCell>
                                    <TableCell>Make</TableCell>
                                    <TableCell>Model</TableCell>
                                    <TableCell>Year</TableCell>
                                    <TableCell>Color</TableCell>
                                    <TableCell>Nickname</TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {this.state.loading ? (
                                    <TableRow>
                                        <TableCell colSpan={8} align="center" className="py-5">
                                            <CircularProgress color="primary"/>
                                        </TableCell>
                                    </TableRow>
                                ) : (
                                    this.state.rows.length === 0 ? (
                                        <TableRow style={{height: 15 * this.state.perPage}}>
                                            <TableCell colSpan={8} style={{textAlign: "center"}}>No
                                                transaction</TableCell>
                                        </TableRow>
                                    ) : (
                                        this.state.rows.slice(this.state.currentPageTable * this.state.perPage, this.state.currentPageTable * this.state.perPage + this.state.perPage)
                                            .map((row, index)=>{
                                            return (
                                                <TableRow key={index}>
                                                    <TableCell >{row.LicensePlateState}</TableCell>
                                                    <TableCell >{row.LicensePlateCountry}</TableCell>
                                                    <TableCell >{row.LicensePlateNumber}</TableCell>
                                                    <TableCell >{row.VehicleMake }</TableCell>
                                                    <TableCell >{row.VehicleModel}</TableCell>
                                                    <TableCell >{row.VehicleYear}</TableCell>
                                                    <TableCell >{row.VehicleColor}</TableCell>
                                                    <TableCell >{row.Nickname}</TableCell>
                                                </TableRow>
                                            )
                                        })
                                    )
                                )}
                            </TableBody>
                        </Table>
                        </div>
                        <TablePagination
                            rowsPerPageOptions={[50, 75, 100]}
                            component="div"
                            count={this.state.totalData}
                            rowsPerPage={this.state.perPage}
                            page={this.state.currentPageTable}
                            backIconButtonProps={{
                              'aria-label': 'previous page',
                            }}
                            nextIconButtonProps={{
                                'aria-label': 'next page',
                            }}
                            onChangePage={this.handleChangePage}
                            onChangeRowsPerPage={this.handleChangeRowsPerPage}
                            ActionsComponent={TablePaginationActions}
                        />
                        
                    </div>
                </div>
            </div>
        </div>
    )
  }
}
