import React, {Component} from "react";
import {Dialog, DialogContent, DialogTitle} from "@material-ui/core";
import DateFormat from "../../../libraries/data-format-helper";

export default class UploadDetail extends Component {
    render() {
        if (this.props.showDetail === false) {
            return false
        }
        let upload = this.props.uploadDetail
        let uploadErrors = (upload["errors"] === "null") ? "N/A" : upload["errors"];
        return (
            <Dialog
                maxWidth={'md'}
                scroll={"body"}
                open={this.props.showDetail}
                onClose={this.props.handleCloseDetail}
                aria-labelledby="form-dialog-title"
                className="dialog-custom">
                <DialogTitle id="form-dialog-title">
                    <div className="row align-items-center">
                        <div className="col-md-6">
                            <span>Uploaded File</span>
                        </div>
                    </div>
                    <button onClick={this.props.handleCloseDetail} className="btn-close">
                        <i className="fas fa-times"></i>
                    </button>
                </DialogTitle>
                <DialogContent>
                    <div>
                        <div className="row">
                            <div className="col-md-6">
                                <label>Type: </label><strong>{upload["type"]}</strong>
                            </div>
                            <div className="col-md-6">
                                <label>Status: </label><strong>{upload["status"]}</strong>
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-md-6">
                                <label>Records: </label><strong>{upload["records"]}/{upload["recordsComplete"]}</strong>
                            </div>
                            <div className="col-md-6">
                                <label>Created At: </label><strong>{DateFormat.dateTime(upload["createdAt"])}</strong>
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-md-12">
                                <label>Errors:</label><br/><strong>{uploadErrors}</strong>
                            </div>
                        </div>
                    </div>
                </DialogContent>
            </Dialog>
        )
    }
}