import React, { Component } from "react";
import ProductConfigList from "./ProductConfigList";
import Api from "../../../libraries/api";

import { toast, ToastContainer } from "react-toastify";

toast.configure({
  autoClose: 2000,
  draggable: false,
});
function updateProduct(productId, statusProduct) {
  var productUpdateConfigUrl = "/products-config/id/" + productId;
  var parameters = { status: statusProduct };
  Api.put(productUpdateConfigUrl, parameters, false).then((resp) => {});
}

class Modules extends Component {
  constructor(props) {
    super(props);
    this.state = { value: "" };
  }

  saveModuleOptions(e) {
    e.preventDefault();
    const productList = document.getElementsByName("productStatus");
    for (var i = 0; i < productList.length; i++) {
      updateProduct(productList[i].value, productList[i].checked);
    }
    toast.success("Successfully updated!", {
      position: toast.POSITION.BOTTOM_CENTER,
    });
  }

  render() {
    return (
      <div>
        <h5 className='text-title-big' style={{marginBottom:'20px'}}>Modules</h5>
        <div className="card col-6">
          <p>Select the modules to be displayed in the mobile app.</p>
          <p>
            <strong>Basic account:</strong>
          </p>
          <div>
            <form
              id="frmProductConfig"
              name="frmProductConfig"
              className="form-horizontal"
              onSubmit={this.saveModuleOptions}
              method="Post"
            >
              <ProductConfigList />
              <div class="row">
                <div class="cols-md-1">
                  <input
                    type="submit"
                    value="Update"
                    class="btn btn-blue"
                    style={{ margin: "15px" }}
                  />
                </div>
              </div>
            </form>
          </div>  
        </div>
        <ToastContainer />
      </div>
    );
  }
}
export default Modules;
