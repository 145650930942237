import React, {Component} from 'react';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import TablePagination from '@material-ui/core/TablePagination';
import CircularProgress from '@material-ui/core/CircularProgress';
import SimpleReactValidator from "simple-react-validator";
import Api from "../../../libraries/api";
import {Link} from 'react-router-dom';
import InputAdornment from "@material-ui/core/InputAdornment";
import IconButton from "@material-ui/core/IconButton";
import TextField from "@material-ui/core/TextField";
import {toast, ToastContainer} from "react-toastify";
import {bindActionCreators} from "redux";
import {RootActions} from "../../../shared/root-action";
import {connect} from "react-redux";
import TablePaginationActions from "../../presentational/table-pagination-actions";
import Map from '../../presentational/map';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import NumberFormat from 'react-number-format';
import { CSVLink } from 'react-csv';
import DateFormat from '../../../libraries/data-format-helper';
import { history } from '../../../shared/configure-store';
import SearchInput from './search-input'




const validator = new SimpleReactValidator({locale: process.env.REACT_APP_LOCALE});

toast.configure({
    autoClose: 2000,
    draggable: false,
});

class TransactionList extends Component {
    constructor(props) {

        super(props);

        this.state = {
            firstTime: true,
            loading: true,
            loadingButton: false,
            loadingZipcode: false,
            errors: {},
            search: '',
            filter: '',
            promoCheck: false,


            showDetail: false,

            rows: [],
            sendPageNumber: '',
            sendPerPage: '',
            total: 0,
            perPage: 50,
            currentPage: 1,
            currentPageTable: 0,
            totalData: 0,

            idUser: this.props.user && this.props.user,
            idTransaction: '',
            name: '',
            type: '',
            status: '',
            date: '',
            amount: '',
            
            transactions: [],

            dataCSV:[],
            allRows:[],
            users:[],
        };
        this.csvLink = React.createRef();
        this.handleChange = this.handleChange.bind(this);
        this.handleKeyPressSearch = this.handleKeyPressSearch.bind(this);
        this.handleClickSearch = this.handleClickSearch.bind(this);
        this.handleShowDetail = this.handleShowDetail.bind(this);
        this.handleClose = this.handleClose.bind(this);
        this.getAccountUser()
    }

    componentDidMount() {
        this.__fetchData(false);
    }

    __fetchData = update => {
        let page = update ? parseInt(this.state.currentPage + 1) : this.state.currentPage;
        let searchById = this.state.idUser ? '/' + this.state.idUser : '';
        var todayDate = new Date();
        var getMonth = todayDate.toLocaleString("default", { month: "2-digit" });
        var getDay = todayDate.toLocaleString("default", { day: "2-digit" });
        let todayDateStr = todayDate.getFullYear() + "-" + getMonth + "-" + getDay;
        let searchStartDate = (this.state.startDate) ? this.state.startDate : ''
        let searchEndDate = (
            (this.state.startDate && this.state.endDate) ? this.state.endDate : 
            (this.state.startDate) ? todayDateStr : ''
        )
        let route;
        if (this.state.idUser) {
            route = '/accounts' + searchById + '/transactions?perPage=' + this.state.perPage + '&pageNumber=' + page + '&startDate=' + 
            searchStartDate + '&endDate=' + searchEndDate;
        } else {
            route = '/accounts' + searchById + '/transactions?perPage=' + this.state.perPage + '&pageNumber=' + page + '&startDate=' + 
            searchStartDate + '&endDate=' + searchEndDate + '&keyword=' + this.state.search + '&filter=' + this.state.filter;
        }
        Api.get(route).then(resp => {
            console.log(resp.data);
            if (resp.data) {
                resp.data.transactions.forEach(function (o) {
                    o.selected = false;
                });
                if (this.state.idUser) {
                    this.setState({
                        loading: false,
                        rows: resp.data.transactions,
                        balance: resp.data.balance,
                        totalData : resp.data.transactions.length
                    });
                } else {
                    this.setState({
                        loading: false,
                        rows: resp.data.transactions,
                        total: resp.data.meta.total,
                        perPage: parseInt(resp.data.meta.perPage),
                        currentPage: resp.data.meta.currentPage,
                        currentPageTable: resp.data.meta.currentPage - 1,
                        totalData: resp.data.meta.totalData,
                    });
                }
            }
        }).catch(err => {
            console.log(err);
        });
    };

    handleChange(e, prop) {
        console.log(e)
        this.setState({
            [prop]: e
        })
    };

    handleKeyPressSearch = (event) => {
        if (event.key === 'Enter') {
            this.handleClickSearch();
        }
    };

    handleClickSearch() {
        this.setState({
            loading: true,
        });
        this.__fetchData(false);
    };

    handleShowDetail = (data) => {
        let arrObjRows = this.state.rows;
        arrObjRows.forEach(function (o) {
            o.selected = o.id === data.id;
        });
        this.setState({
            showDetail: true,
            }
        );
    };


    handleClose = e => {
        this.setState(prevState => ({
                showDetail: false,
            })
        )
    };

    handleShowEdit = e => {
        e.preventDefault();

        this.setState({
            showEdit: true,
        })
    };

    handleCloseEdit = e => {
        e.preventDefault();
        this.setState({
            showEdit: false,
        })
    };

    showMessage = (status, message) => {
        if (status) {
            toast.success(message, {
                position: toast.POSITION.BOTTOM_RIGHT
            });
        } else {
            toast.error(message, {
                position: toast.POSITION.BOTTOM_RIGHT
            });
        }
    };

    handleUpdate = e => {
        e.preventDefault();
        if (!validator.allValid()) {
            this.setState({
                    errors: validator.getErrorMessages()
                }
            );
            return false;
        }
        this.setState({
                errors: {},
                loadingButton: true,
            }
        );
        this.showMessage(true, 'Success');
    };

    handleChangePage = (event, newPage) => {
        this.setState({
            rows: [],
            currentPage: newPage,
            currentPageTable: newPage,
            loading: true,
        }, () => {
            this.__fetchData(true);
        });
    };

    handleChangeRowsPerPage = event => {
        
        this.setState({
            perPage: event.target.value,
            currentPage: 1,
            loading: true,
        }, () => {
            this.__fetchData(false);
        });
    };

    handleDownloadCSV (){
        let searchById = this.state.idUser ? '/' + this.state.idUser : '';
        var route = this.state.idUser ? 
          '/accounts' + searchById + '/transactions'
        :
          '/accounts/transactions?perPage=10000&pageNumber=0'
        this.setState({ downloading:true })
        Api.get(route).then(resp => {
            if (resp.data) {
                resp.data.transactions.forEach(function (o) {
                    o.selected = false;
                });
                this.setState({
                    allRows: resp.data.transactions,
                },()=>{
                    this.setState({dataCSV:this.state.allRows})
                    this.setNameCSV();
                    setTimeout(() => { this.DownloadCSV()}, 200);
                });
            }
        }).catch(err => {
            console.log(err);
            this.setState({ downloading: false})
        });
    }

    DownloadCSV(){
        this.setState({ downloading:false },()=>{
            setTimeout(() => { 
                this.csvLink.current.link.click()
            })
        })
    }

    setNameCSV(){
        var currentTime = Date.now();
        var date = DateFormat.dateTime(currentTime)
        var name = 'Transactions-'+date+'.csv'
        this.setState({nameCSV:name})
    }

    getAccountUser() {
        let url = "/accounts?perPage=" + 50 + "&pageNumber=" + 0 + "&search=";
        Api.get(url, [], false).then((resp) => {
            this.setState({
                users: resp["data"]["users"],
            });
        });
    }

    handleGoToAccount(userId){
        if(userId && userId !== ''){
            history.push('/accounts/detail/' + userId)
        }
    }

    getDescription(description){
        let str = description.replace( 'error making charge: ', '')
        let desc = str === null ? 'NA' : str
        try {
            var json = JSON.parse(desc)
            return json.message
          } catch (error) {
            return desc
          }
    }

    render() {
        return (
            <div >
                {this.state.idUser ?(''):
                    <h5 className='text-title-big' style={{marginBottom:'20px'}}>Transactions list</h5>
                }
                <div className=" col-12 card">
                    <SearchInput handleChange = {this.handleChange} handleKeyPressSearch={this.handleKeyPressSearch} fetchData={this.__fetchData} userID={this.state.idUser} />
                    <div className='row'>
                        <div className="search-bar" style={{marginLeft:'15px',marginTop:'10px'}}>
                            <a href={"/uploads"} className={"btn btn-info"}>Upload Transactions</a>
                        </div>
                        <div style={{marginLeft:'20px',marginTop:'10px'}}>
                            <button  className="btn btn-info" 
                                    onClick={()=>this.handleDownloadCSV()}
                            > 
                                { this.state.downloading ?<i className="fa fa-spinner fa-spin"/> : ('') } &nbsp;
                                <i className="fas fa-download" style={{marginRight:'3px'}}/>
                                &nbsp;Download CSV
                            </button>
                            <CSVLink 
                                data={this.state.dataCSV}
                                target="_blank"
                                filename={this.state.nameCSV}
                                ref={this.csvLink}
                            />
                        </div>
                    </div>
                    <div className="table-wrapper">
                        <Table className="table-list mt-3" size="small">
                            <TableHead>
                                <TableRow>
                                    <TableCell>Name</TableCell>
                                    <TableCell>Type</TableCell>
                                       <TableCell>Status</TableCell>
                                    <TableCell>Date</TableCell>
                                    <TableCell>Amount</TableCell>
                                    <TableCell>Discount</TableCell>
                                    <TableCell>Fee</TableCell>
                                    <TableCell>Description</TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>

                                {this.state.loading ? (
                                    <TableRow>
                                        <TableCell colSpan={8} align="center" className="py-5">
                                            <CircularProgress color="primary"/>
                                        </TableCell>
                                    </TableRow>
                                ) : (
                                    this.state.rows.length === 0 ? (
                                        <TableRow style={{height: 33 * this.state.perPage}}>
                                            <TableCell colSpan={8} style={{textAlign: "center"}}>No
                                                transaction</TableCell>
                                        </TableRow>
                                    ) : (
                                        this.state.rows.map((row, index) => {

                                            return (
                                                <TableRow
                                                    key={index}
                                                    onClick={() => this.handleGoToAccount(row.userId)}
                                                    /*onClick={() => {
                                                        this.handleShowDetail(row);
                                                    }}*/
                                                    selected={row.selected}
                                                >
                                                    <TableCell>{row.name === '' ? 'NA' : row.name}</TableCell>
                                                    <TableCell>{row.type === null ? 'NA' : row.type}</TableCell>
                                                    <TableCell>{row.status === null ? 'NA' : row.status === ""?<span className='text-danger'>Declined</span>:<spam>{row.status}</spam>}</TableCell>
                                                    <TableCell>{row.date === null ? 'NA' : row.date}</TableCell>
                                                    <TableCell>{row.amount === null ? 'NA' : parseFloat(row.amount).toFixed(2)}</TableCell>
                                                    <TableCell>{row.discountAmount === null ? 'NA' : row.discountAmount}</TableCell>
                                                    <TableCell>{row.fee === null ? 'NA' : row.fee}</TableCell>
                                                    <TableCell>{this.getDescription(row.description)}</TableCell>
                                                </TableRow>
                                            )
                                        })
                                    )
                                )}

                            </TableBody>
                        </Table>
                    </div>
                    <TablePagination
                        rowsPerPageOptions={[50, 75, 100]}
                        component="div"
                        count={this.state.totalData}
                        rowsPerPage={this.state.perPage}
                        page={this.state.currentPageTable}
                        backIconButtonProps={{
                            'aria-label': 'previous page',
                        }}
                        nextIconButtonProps={{
                            'aria-label': 'next page',
                        }}
                        onChangePage={this.handleChangePage}
                        onChangeRowsPerPage={this.handleChangeRowsPerPage}
                        ActionsComponent={TablePaginationActions}
                    />
                </div>
                <Dialog
                    maxWidth={'md'}
                    open={this.state.showDetail}
                    onClose={this.handleClose}
                    aria-labelledby="form-dialog-title"
                    className="dialog-custom">
                    <DialogTitle id="form-dialog-title">
                        <div className="row align-items-center">
                            <div className="col-md-6">
                                <span>Ticket {this.state.ticketNumber}</span>
                            </div>
                            <div className="col-md-6">
                                <p className="mb-0"><label className="title d-inline">Status</label> <i
                                    className="status text-capitalize">{this.state.status}</i></p>
                            </div>
                        </div>
                        <button onClick={this.handleClose} className="btn-close">
                            <i className="fas fa-times"> </i>
                        </button>
                    </DialogTitle>
                    <DialogContent>
                        <div>
                            <div className="row">
                                <div className="col-md-6">
                                    <label className="title">Account number</label>
                                    <p className="pl-2 pl-lg-3">{
                                        this.state.transponderNumber &&
                                        <NumberFormat className="title mb-0 numb" value={this.state.transponderNumber}
                                                      displayType={'text'} format="#### ##### ##### #"/>
                                    }</p>
                                    <Link to={'/accounts/' + this.state.transponderNumber}
                                          className="btn btn-blue-trans mb-3">Go to Account</Link>

                                    <label className="title">Contact</label>
                                    <p className="pl-2 pl-lg-3">{this.state.fullName}<br/>
                                        {this.state.email}<br/>
                                        {this.state.phone}
                                    </p>

                                    <label className="title mb-0">Location</label>
                                    <Map center={{lat: this.state.latitude, lng: this.state.longitude}} zoom={4}/>
                                </div>
                                <div className="col-md-6">
                                    <div className="row">
                                        <div className="col-md-6">
                                            <label className="title">Date created</label>
                                            <p className="pl-2 pl-lg-3">{this.state.created}</p>
                                        </div>
                                        <div className="col-md-6">
                                            <label className="title">Priority</label>
                                            <p className="pl-2 pl-lg-3">{this.state.priority}</p>
                                        </div>
                                    </div>
                                    <div className="row">
                                        <div className="col-md-6">
                                            <label className="title">Category</label>
                                            <p className="pl-2 pl-lg-3">{this.state.category}</p>
                                        </div>
                                        <div className="col-md-6">
                                            <label className="title">Assignee</label>
                                            <p className="pl-2 pl-lg-3">{this.state.assignee}</p>
                                        </div>
                                        <div className="col-12 mb-2">
                                            <label className="title">Diagnostics</label>
                                            {
                                                this.state.diagnostics &&
                                                this.state.diagnostics.split(' ').map((item, idx) => {
                                                    return (
                                                        <p key={idx} className="mb-0 pl-2 pl-lg-3">{item}</p>
                                                    )
                                                })}
                                        </div>

                                        <div className="col-12">
                                            <label className="title">Content</label>
                                            <p className="pl-2 pl-lg-3">{this.state.content}</p>
                                        </div>
                                        <div className="col-12 text-right">
                                            <a className="btn btn-blue-trans" href={this.state.zendeskProfileUrl}
                                               target="_blank" rel="noopener noreferrer">Open in Zendesk</a>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </DialogContent>
                    <DialogActions>
                    </DialogActions>
                </Dialog>
                <ToastContainer/>

            </div>
        )
    }
}

const mapStateToProps = (state) => {

    return {

        permission_state: state.permission_state,

    };

};

const mapDispatchToProps = (dispatch) => {

    return bindActionCreators(RootActions, dispatch)

};

export default connect(mapStateToProps, mapDispatchToProps)(TransactionList);