import React, {Component} from "react";
import PropTypes from "prop-types";
import {withRouter, Link} from "react-router-dom";
import {bindActionCreators} from "redux";
import {RootActions} from "../../../shared/root-action";
import {connect} from "react-redux";
import AuthHelper from "../../../libraries/auth-helper";

class Sidebar extends Component {

    constructor(props){
        super(props)
        this.state={
            showDropDown:false,
            dropDownTransaction:false,
        }
    }

    static propTypes = {
        match: PropTypes.object.isRequired,
        location: PropTypes.object.isRequired,
        history: PropTypes.object.isRequired,
    };

    handleLogout = () => {
        AuthHelper.logOut();
    };

    handleShowDropDown(){
        this.setState({showDropDown:!this.state.showDropDown})
    }

    handleDropDownTransaction(){
        this.setState({dropDownTransaction:!this.state.dropDownTransaction})
    }

    render() {
        const {location} = this.props;
        const perms = this.props.permission_state;
        return (
            <aside
                className={
                    "side-nav " + (this.props.toggle_sidebar_state ? "show" : "")
                }
            >
                <ul className="nav flex-column">
                    <li className="nav-item name d-md-none">
                        Welcome, {this.props.profile_state.fullName}
                    </li>
                    <li className={"nav-item " + (location.pathname === "/" && "active")}>
                        <Link to="/">Home</Link>
                    </li>
                    {perms.includes("accounts") && (
                        <div >
                              <div  className={location.pathname.includes("/accounts") ? "nav-item2 active" : "nav-item2"}
                                //style={{paddingLeft:'29px',color:'#4C4C4D',fontWeight:'600',fontSize:'16px',cursor:'pointer',marginBottom:'10px' }} 
                                onClick={()=>{this.handleShowDropDown()}}>
                                Accounts
                                { this.state.showDropDown ?
                                    <i class="fas fa-angle-down" style={{marginLeft:'20px',marginTop:'3px'}}/>
                                    :
                                    <i class="fas fa-angle-right" style={{marginLeft:'20px',marginTop:'3px'}}/>
                                }
                            </div>
                            {this.state.showDropDown?
                                <div style={{marginTop:'10px'}}>
                                    <Link className={location.pathname === "/accounts" ? "nav-item2 active":"nav-item2"}
                                        style={{fontSize:'15px'}}
                                        to="/accounts"
                                        >Accounts list
                                    </Link>
                                    <Link className={location.pathname === "/accounts/vehicles" ? "nav-item2 active":"nav-item2"}
                                        style={{fontSize:'15px'}}
                                        to="/accounts/vehicles"
                                        >Vehicles list
                                    </Link>
                                </div>
                                :('')
                            }
                        </div>
                    )}

                    {perms.includes("fulfillment") && (
                        <li
                            className={
                                location.pathname === "/fulfillment"
                                    ? "nav-item active"
                                    : "nav-item"
                            }
                        >
                            <Link to="/fulfillment">Fulfillment</Link>
                        </li>
                    )}

                    {perms.includes("sales") && (
                        <li
                            className={
                                location.pathname === "/sales" ? "nav-item active" : "nav-item"
                            }
                        >
                            <Link to="/sales">Orders</Link>
                        </li>
                    )}

                    {perms.includes("tickets") && (
                        <li
                            className={
                                location.pathname === "/tickets" ? "nav-item active" : "nav-item"
                            }
                        >
                            <Link to="/tickets">Tickets</Link>
                        </li>
                    )}

                    {perms.includes("transactions") && (
                      
                        <div >
                            { /* <li
                            className={
                                location.pathname === "/transactions"
                                    ? "nav-item active"
                                    : "nav-item"
                            }
                        >
                            <Link to="/transactions">Transactions</Link>
                        </li>*/}
                         <div  className={location.pathname.includes("/transactions")||location.pathname.includes("/payment-log") ? "nav-item2 active" : "nav-item2"}
                           style={{paddingRight:'0px'}} 
                           onClick={()=>{this.handleDropDownTransaction()}}
                           >
                           Transactions
                           { this.state.dropDownTransaction ?
                               <i class="fas fa-angle-down" style={{marginLeft:'3px',marginTop:'3px'}}/>
                               :
                               <i class="fas fa-angle-right" style={{marginLeft:'3px',marginTop:'3px'}}/>
                           }
                       </div>
                       {this.state.dropDownTransaction?
                           <div style={{marginTop:'10px'}}>
                               <Link className={location.pathname === "/transactions" ? "nav-item2 active":"nav-item2"}
                                   style={{fontSize:'15px'}}
                                   to="/transactions"
                                   >All 
                               </Link>
                               <Link className={location.pathname === "/payment-log" ? "nav-item2 active":"nav-item2"}
                                   style={{fontSize:'15px'}}
                                   to="/payment-log"
                                   >Declineds
                               </Link>
                           </div>
                           :('')
                       }
                   </div>
                    )}

                    {perms.includes("promotions") && (
                        <li
                            className={
                                location.pathname === "/promotions" ? "nav-item active" : "nav-item"
                            }
                        >
                            <Link to="/promotions">Promotions</Link>
                        </li>
                    )}

                    {perms.includes("modules") && (
                        <li
                            className={
                                location.pathname === "/modules" ? "nav-item active" : "nav-item"
                            }
                        >
                            <Link to="/modules">Modules</Link>
                        </li>
                    )}

                    {perms.includes("payrules") && (
                        <li
                            className={
                                location.pathname === "/payment-rules" ? "nav-item active" : "nav-item"
                            }
                        >
                            <Link to="/payment-rules">Pay rules</Link>
                        </li>
                    )}

                    {perms.includes("reports") && (
                        <li
                            className={
                                location.pathname === "/reports" ? "nav-item active" : "nav-item"
                            }
                        >
                            <Link to="/reports">Reports</Link>
                        </li>
                    )}

                    {perms.includes("users") && (
                        <li
                            className={
                                location.pathname === "/access-management"
                                    ? "nav-item active"
                                    : "nav-item"
                            }
                        >
                            <Link to="/access-management">Access Mgmt</Link>
                        </li>
                    )}
                    <li
                        className={
                            location.pathname === "/" ? "nav-item active" : "nav-item"
                        }
                    >
                        <button onClick={this.handleLogout}>Sign out</button>
                    </li>
                </ul>
            </aside>
        );
    }
}

const mapStateToProps = (state) => {
    return {
        toggle_sidebar_state: state.toggle_sidebar_state,

        profile_state: state.profile_state,

        permission_state: state.permission_state,
    };
};

const mapDispatchToProps = (dispatch) => {
    return bindActionCreators(RootActions, dispatch);
};

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(withRouter(Sidebar));
