import React, {Component} from "react";
import {Paper} from "@material-ui/core";

export default class UploadFile extends Component {

    render() {
        return (
            <div>
                <Paper>
                    <div><strong>Select a csv file to upload your employees</strong></div>
                    <br/>
                    <div className="form-group row">
                        <div className="col-md-12">
                            <input type="file" accept=".csv,application/vnd.ms-excel" name="file"
                                   onChange={this.props.handleFileInputChange} key={this.props.file}/>
                        </div>
                    </div>
                    <div className="form-group row">
                        <div className="col-md-12">
                            <button
                                onClick={this.props.saveUpload} className="btn btn-blue"
                                disabled={this.props.loading && 'disabled'}>Upload{this.props.loading &&
                            <i className="fa fa-spinner fa-spin"> </i>}</button>
                        </div>
                    </div>
                </Paper>
            </div>
        )
    }
}