import React, {Component} from "react";
import AppBar from "@material-ui/core/AppBar";
import Tabs from "@material-ui/core/Tabs";
import Tab from "@material-ui/core/Tab";
import Typography from "@material-ui/core/Typography";
import Box from "@material-ui/core/Box";
import UsersSummaryReport from "./users-summary";


function TabPanel(props) {
    const {children, value, index, ...other} = props;

    return (
        <Typography
            component="div"
            role="tabpanel"
            hidden={value !== index}
            id={`scrollable-auto-tabpanel-${index}`}
            aria-labelledby={`scrollable-auto-tab-${index}`}
            {...other}
        >
            <Box p={3}>{children}</Box>
        </Typography>
    );
}

function a11yProps(index) {
    return {
        id: `scrollable-auto-tab-${index}`,
        'aria-controls': `scrollable-auto-tabpanel-${index}`,
    };
}

class AdminReports extends Component {

    constructor(props) {
        super(props);
        this.state = {
            activeTab: 0,
            handleTabChange: 0,
            userSummary: true
        }
    }

    render() {
        return (
            
                <div className="row">
                    <div className="col-12">
                        <AppBar position="static" color="default"  style={{marginLeft:'-20px',width:'103%'}}>
                            <div className="row align-items-center">
                                <div className="col-md-6">
                                    <h2>Reports</h2>
                                </div>
                            </div>

                            <Tabs
                                value={this.state.activeTab}
                                onChange={this.handleTabChange}
                                indicatorColor="primary"
                                textColor="primary"
                                variant="scrollable"
                                scrollButtons="auto"
                                aria-label="scrollable auto tabs example"
                            >
                                <Tab label="Users Summary" {...a11yProps(0)} />
                            </Tabs>
                        </AppBar>
                        <TabPanel value={this.state.activeTab} index={0}>
                            {(this.state.userSummary === true) ? (<UsersSummaryReport/>) : ('')}
                        </TabPanel>
                    </div>
                </div>
            
        );
    }
}


export default AdminReports