import { CircularProgress } from '@material-ui/core';
import { Chart } from 'chart.js';
import React, { Component } from 'react'
import Api from '../../../libraries/api';

export default class ChartPaymentsStatus extends Component {
  constructor(props) {
    super(props);
    this.chartRef = React.createRef();
    this.state = {
        period: 'week',
        loading: false,
    };
    this.getPaymentsChart('week')
    this.handleChange = this.handleChange.bind(this);
    this.getPaymentsChart = this.getPaymentsChart.bind(this);
}

getPaymentsChart(period) {
    Api.get('/top-up/get-payment-methods-status?showIn=' + period, false).then(resp => {
        if (resp.data) {
            let labels = resp.data.columns.sort()
            let active = resp.data.active.sort()
            let perExpire = resp.data.perExpire.sort()
            let expired = resp.data.expired.sort()
            this.setState({loading: false})
            this.__chart(labels, active, perExpire,expired)
        }
    }).catch(err => {
        console.log(err);
    });
}

__chart = (labels,active, perExpire,expired) => {
    var barChartData = {
        labels: labels,
        datasets: [{
            label: 'Active',
            backgroundColor: '#008299',
            borderColor: '#008299',
            borderWidth: 1,
            hiddenLegend: true,
            datalabels: {
                color: 'transparent'
            },
            data: active
        }, {
            label: 'Per expire',
            backgroundColor: '#FF911B',
            borderColor: '#c48000',
            borderWidth: 1,
            hiddenLegend: true,
            datalabels: {
                color: 'transparent'
            },
            data: perExpire
        },{
          label: 'Expired',
          backgroundColor: '#C60808',
          borderColor: '#ff234e',
          borderWidth: 1,
          hiddenLegend: true,
          datalabels: {
              color: 'transparent'
          },
          data: expired
      }]
    };
    let myChartRef = this.chartRef.current.getContext("2d");
    new Chart(myChartRef, {
        type: 'bar',
        data: barChartData,
        options: {
            responsive: true,
            maintainAspectRatio: false,
            scales: {
                yAxes: [{
                    ticks: {
                        beginAtZero: true,
                        precision: 0
                    }
                }]
            },
            legend: {
                position: 'top',
            },
        }
    }).update();
}

handleChange = (target, prop) => {
    this.setState({period: target, loading: true}, () => {
        this.getPaymentsChart(target)
    });
};

render() {
    return (
        <div>
            <div className="col-12" style={{marginTop:'0px',padding:'0px'}}>
                <div className="col-12 card">
                    <div className="row title-dashboard">
                        <div className="col-9">
                            <h2>Payments Methods Status</h2>
                        </div>
                    </div>
                    <div className="d-md-flex justify-content-center mt-4 mb-4">
                        <nav className="nav nav-filter">
                            <button className={"nav-link pl-0 " + (this.state.period === 'day' && 'active')}
                                    onClick={() => this.handleChange('day', 'period')}
                                    >Daily
                            </button>
                            <button className={"nav-link " + (this.state.period === 'week' && 'active')}
                                    onClick={() => this.handleChange('week', 'period')}
                                    >Weekly
                            </button>
                            <button className={"nav-link " + (this.state.period === 'month' && 'active')}
                                    onClick={() => this.handleChange('month', 'period')}
                                    >Monthly
                            </button>
                        </nav>
                    </div>
                    <ShowPaymentsStatusChart loading={this.state.loading} chartRef={this.chartRef}/>
                </div>
            </div>
        </div>
    )
  }
}

class ShowPaymentsStatusChart extends Component {
  render() {
    if (this.props.loading === true) {
        return(
            <div className='col-12 content-center'>
                 <CircularProgress/>
            </div>
        )
    } else {
        return (
            <div className="chart-container">
                <canvas id="myChart" ref={this.props.chartRef}/>
            </div>
        )
    }
  }
}
