import React, {Component} from 'react';
import Chart from "chart.js";
// eslint-disable-next-line
import ChartDataLabels from 'chartjs-plugin-datalabels';
import _ from "lodash";
import Api from "../../../libraries/api";
import ChartPaymentsStatus from './chart-payments-status';

let totalizer = {
    id: 'totalizer',

    beforeUpdate: chart => {
        let utmost = {};
        let stacktotal = {};
        chart.data.datasets.forEach((dataset, datasetIndex) => {
            if (chart.isDatasetVisible(datasetIndex)) {
                utmost[dataset.stack] = datasetIndex;

                dataset.data.forEach((value, index) => {
                    if (stacktotal[dataset.stack]) {
                        stacktotal[dataset.stack][index] = (stacktotal[dataset.stack][index] || 0) + value;
                    } else {
                        stacktotal[dataset.stack] = [];
                        stacktotal[dataset.stack][index] = value;
                    }
                })
            }
        });

        chart.$totalizer = {
            utmost: utmost,
            stacktotal: stacktotal
        }
    }
};

let borderColor = 'transparent',
    borderWidth = 1,
    barPercentage = 1;

let numberWithCommas = function (x) {
    return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
};

Chart.pluginService.register({
    beforeDraw: function (chart) {
        if (chart.config.options.elements.center) {
            //Get ctx from string
            var ctx = chart.chart.ctx;

            //Get options from the center object in options
            var centerConfig = chart.config.options.elements.center;
            var fontStyle = centerConfig.fontStyle || 'Arial';
            var txt = centerConfig.text;
            var txtBottom = centerConfig.textBottom;
            var txtTop = centerConfig.textTop;
            var color = centerConfig.color || '#000';
            var colorText = centerConfig.colorText || '#fff';
            var sidePadding = centerConfig.sidePadding || 20;
            var sidePaddingCalculated = (sidePadding / 100) * (chart.innerRadius * 2)
            //Start with a base font of 30px
            ctx.font = "30px " + fontStyle;

            //Get the width of the string and also the width of the element minus 10 to give it 5px side padding
            var stringWidth = ctx.measureText(txt).width;
            var elementWidth = (chart.innerRadius * 2) - sidePaddingCalculated;

            // Find out how much the font can grow in width.
            var widthRatio = elementWidth / stringWidth;
            var newFontSize = Math.floor(20 * widthRatio);
            var elementHeight = (chart.innerRadius * 2);

            // Pick a new font size so it will not be larger than the height of label.
            var fontSizeToUse = Math.min(newFontSize, elementHeight);

            //Set font settings to draw it correctly.
            ctx.textAlign = 'center';
            ctx.textBaseline = 'middle';
            var centerX = ((chart.chartArea.left + chart.chartArea.right) / 2);
            var centerY = chart.chartArea.bottom * 0.85;
            ctx.font = fontSizeToUse + "px " + fontStyle;
            ctx.fillStyle = color;

            var centerXBottom = (chart.chartArea.left + chart.chartArea.right) * 0.12;
            var centerXTop = (chart.chartArea.left + chart.chartArea.right) * 0.88;

            //Draw text in center
            ctx.fillText(txt, centerX, centerY);

            ctx.globalCompositeOperation = 'destination-over';

            ctx.fillStyle = colorText;

            ctx.fillText(txtBottom, centerXBottom, centerY);
            ctx.fillText(txtTop, centerXTop, centerY);
        }
    }
});

class ChartOld extends Component {

    constructor(props) {

        super(props);

        this.chartRefBar = React.createRef();
        this.chartRef = React.createRef();
        this.chartRefPie = React.createRef();
        this.chartRefProgress = React.createRef();
        this.chartRefAverageDollarSummary = React.createRef();
        this.chartRefTotalPaymentTransaction = React.createRef();
        this.chartRefTotalPaymentVolume = React.createRef();
        this.chartRefAverageTollTransactionCost = React.createRef();
        this.chartRefTotalPaymentGateway = React.createRef();
        this.chartRefTollActivity = React.createRef();
        this.chartRefPrepayAmount = React.createRef();
        this.chartRefTollTransactionType = React.createRef();
        this.chartRefAverageTollAccount = React.createRef();

        this.state = {
            firstTimeChartToll: true,
            firstTimeChartCreation: true,
            period: 'daily',
            periodCreation: 'daily',
            dataTolls: {
                daily: {},
                weekly: {},
                monthly: {},
            },
            currentToll: {
                data: [],
                label: [],
            },
            dataCreation_daily: {},
            dataCreation_weekly: {},
            dataCreation_monthly: {},
            currentCreation: {},
            myChartRefAcc: undefined,
            myChartRefCreation: undefined,
            onBoardingAvgTimeD: '',
            onBoardingAvgTimeH: '',
            onBoardingAvgTimeM: '',
            totalUsers: '',

            dataAverageDollarSummary: {},
            currentAverageDollarSummary: {},
            myChartRefAverageDollarSummary: undefined,
            periodAverageDollarSummary: 'weekly',
            firstTimeChartAverageDollarSummary: true,

            dataTotalPaymentTransaction: {},
            currentTotalPaymentTransaction: {},
            myChartRefTotalPaymentTransaction: undefined,
            periodTotalPaymentTransaction: 'weekly',
            firstTimeChartTotalPaymentTransaction: true,

            dataTotalPaymentVolume: {},
            currentTotalPaymentVolume: {},
            myChartRefTotalPaymentVolume: undefined,
            periodTotalPaymentVolume: 'weekly',
            firstTimeChartTotalPaymentVolume: true,

            dataAverageTollTransactionCost: {},
            currentAverageTollTransactionCost: {},
            myChartRefAverageTollTransactionCost: undefined,
            periodAverageTollTransactionCost: 'weekly',
            firstTimeChartAverageTollTransactionCost: true,

            dataTotalPaymentGateway: {},
            currentTotalPaymentGateway: {},
            myChartRefTotalPaymentGateway: undefined,
            periodTotalPaymentGateway: 'weekly',
            firstTimeChartTotalPaymentGateway: true,

            dataTollActivity: {},
            currentTollActivity: {},
            myChartRefTollActivity: undefined,
            periodTollActivity: 'weekly',
            firstTimeChartTollActivity: true,

            dataPrepayAmount: {},
            currentPrepayAmount: {},
            myChartRefPrepayAmount: undefined,
            periodPrepayAmount: 'weekly',
            firstTimeChartPrepayAmount: true,

            dataTollTransactionType: {},
            currentTollTransactionType: {},
            myChartRefTollTransactionType: undefined,
            periodTollTransactionType: 'weekly',
            firstTimeChartTollTransactionType: true,

            dataAverageTollAccount: {},
            currentAverageTollAccount: {},
            myChartRefAverageTollAccount: undefined,
            periodAverageTollAccount: 'weekly',
            firstTimeChartAverageTollAccount: true,

            disableWeekly: true,
            disableMonthly: true,
        };

        this.buildChart = this.buildChart.bind(this);
        this.convertMinsToHrsMins = this.convertMinsToHrsMins.bind(this);
        this.kFormatter = this.kFormatter.bind(this);
    }

    convertMinsToHrsMins(mins) {
        let h = Math.floor(mins / 60);
        let m = mins % 60;
        return [false, h, m];
    }

    convertMinsToDaysHrsMins(time) {
        let d = Math.floor(time / 24 / 60);
        let h = Math.floor(time / 60 % 24);
        let m = Math.floor(time % 60);
        return [d, h, m];
    }

    kFormatter(num) {
        return Math.abs(num) > 999 ? Math.sign(num) * ((Math.abs(num) / 1000).toFixed(1)) + 'k' : Math.sign(num) * Math.abs(num)
    }

    componentDidUpdate(prevProps, prevState) {

        if ((this.state.period !== prevState.period || this.state.firstTimeChartToll) && typeof this.state.myChartRefAcc !== 'undefined') {

            let filter = this.state.period;

            this.setState({
                firstTimeChartToll: false,
                currentToll: this.state.dataTolls[filter]
            }, () => this.buildChart(this.state.myChartRefAcc, this.state.currentToll));

        }
        if ((this.state.periodCreation !== prevState.periodCreation || this.state.firstTimeChartCreation) && typeof this.state.myChartRefCreation !== 'undefined') {

            let filter = this.state.periodCreation;

            this.setState({
                firstTimeChartCreation: false,
                currentCreation: this.state['dataCreation_' + filter]
            }, () => this.buildChartCreation(this.state.myChartRefCreation, this.state.currentCreation));

        }
        if ((this.state.periodAverageDollarSummary !== prevState.periodAverageDollarSummary || this.state.firstTimeChartAverageDollarSummary) && typeof this.state.myChartRefAverageDollarSummary !== 'undefined') {

            let filter = this.state.periodAverageDollarSummary;

            this.setState({
                firstTimeChartAverageDollarSummary: false,
                currentAverageDollarSummary: this.state.dataAverageDollarSummary[filter]
            }, () => this.buildChartAverageDollarSummary(this.state.myChartRefAverageDollarSummary, this.state.currentAverageDollarSummary));

        }
        if ((this.state.periodTotalPaymentTransaction !== prevState.periodTotalPaymentTransaction || this.state.firstTimeChartTotalPaymentTransaction) && typeof this.state.myChartRefTotalPaymentTransaction !== 'undefined') {

            let filter = this.state.periodTotalPaymentTransaction;

            this.setState({
                firstTimeChartTotalPaymentTransaction: false,
                currentTotalPaymentTransaction: this.state.dataTotalPaymentTransaction[filter]
            }, () => this.buildChartTotalPaymentTransaction(this.state.myChartRefTotalPaymentTransaction, this.state.currentTotalPaymentTransaction));

        }
        if ((this.state.periodTotalPaymentVolume !== prevState.periodTotalPaymentVolume || this.state.firstTimeChartTotalPaymentVolume) && typeof this.state.myChartRefTotalPaymentVolume !== 'undefined') {

            let filter = this.state.periodTotalPaymentVolume;

            this.setState({
                firstTimeChartTotalPaymentVolume: false,
                currentTotalPaymentVolume: this.state.dataTotalPaymentVolume[filter]
            }, () => this.buildChartTotalPaymentVolume(this.state.myChartRefTotalPaymentVolume, this.state.currentTotalPaymentVolume));

        }
        if ((this.state.periodAverageTollTransactionCost !== prevState.periodAverageTollTransactionCost || this.state.firstTimeChartAverageTollTransactionCost) && typeof this.state.myChartRefAverageTollTransactionCost !== 'undefined') {

            let filter = this.state.periodAverageTollTransactionCost;

            this.setState({
                firstTimeChartAverageTollTransactionCost: false,
                currentAverageTollTransactionCost: this.state.dataAverageTollTransactionCost[filter]
            }, () => this.buildChartAverageTollTransactionCost(this.state.myChartRefAverageTollTransactionCost, this.state.currentAverageTollTransactionCost));

        }
        if ((this.state.periodTotalPaymentGateway !== prevState.periodTotalPaymentGateway || this.state.firstTimeChartTotalPaymentGateway) && typeof this.state.myChartRefTotalPaymentGateway !== 'undefined') {

            let filter = this.state.periodTotalPaymentGateway;

            this.setState({
                firstTimeChartTotalPaymentGateway: false,
                currentTotalPaymentGateway: this.state.dataTotalPaymentGateway[filter]
            }, () => this.buildChartTotalPaymentGateway(this.state.myChartRefTotalPaymentGateway, this.state.currentTotalPaymentGateway));

        }
        if ((this.state.periodTollActivity !== prevState.periodTollActivity || this.state.firstTimeChartTollActivity) && typeof this.state.myChartRefTollActivity !== 'undefined') {

            let filter = this.state.periodTollActivity;

            this.setState({
                firstTimeChartTollActivity: false,
                currentTollActivity: this.state.dataTollActivity[filter]
            }, () => this.buildChartTollActivity(this.state.myChartRefTollActivity, this.state.currentTollActivity));

        }
        if ((this.state.periodPrepayAmount !== prevState.periodPrepayAmount || this.state.firstTimeChartPrepayAmount) && typeof this.state.myChartRefPrepayAmount !== 'undefined') {

            let filter = this.state.periodPrepayAmount;

            this.setState({
                firstTimeChartPrepayAmount: false,
                currentPrepayAmount: this.state.dataPrepayAmount[filter]
            }, () => this.buildChartPrepayAmount(this.state.myChartRefPrepayAmount, this.state.currentPrepayAmount));

        }
        if ((this.state.periodTollTransactionType !== prevState.periodTollTransactionType || this.state.firstTimeChartTollTransactionType) && typeof this.state.myChartRefTollTransactionType !== 'undefined') {

            let filter = this.state.periodTollTransactionType;

            this.setState({
                firstTimeChartTollTransactionType: false,
                currentTollTransactionType: this.state.dataTollTransactionType[filter]
            }, () => this.buildChartTollTransactionType(this.state.myChartRefTollTransactionType, this.state.currentTollTransactionType));

        }
        if ((this.state.periodAverageTollAccount !== prevState.periodAverageTollAccount || this.state.firstTimeChartAverageTollAccount) && typeof this.state.myChartRefAverageTollAccount !== 'undefined') {

            let filter = this.state.periodAverageTollAccount;

            this.setState({
                firstTimeChartAverageTollAccount: false,
                currentAverageTollAccount: this.state.dataAverageTollAccount[filter]
            }, () => this.buildChartAverageTollAccount(this.state.myChartRefAverageTollAccount, this.state.currentAverageTollAccount));

        }
    }

    componentDidMount() {

        let innerWidth = window.innerWidth;

        Api.get('/reports/histogram-created-users?range=1d', true).then(resp => {

            if (resp.data) {

                let dataStackedBar = resp.data;

                this.setState({
                    dataCreation_daily: dataStackedBar
                });

                let arrTotal = dataStackedBar.barDate.map(x => 0);

                let tenth = parseInt(_.max(dataStackedBar.SumPerdates) * 0.1) + 1;
                let twentieth = parseInt(_.max(dataStackedBar.SumPerdates) * 0.2) - 1;

                if (twentieth === -1) {
                    twentieth = 0;
                }

                let numPadding = _.max(dataStackedBar.SumPerdates) + tenth;

                if (this.chartRefBar.current) {
                    let myChartRefBar = this.chartRefBar.current.getContext("2d");

                    let myChartRefCreation = new Chart(myChartRefBar, {
                        type: 'bar',
                        data: {
                            //Bring in data
                            labels: dataStackedBar.barDate,
                            datasets: [
                                {
                                    label: dataStackedBar.bar3.label,
                                    data: dataStackedBar.bar3.value,
                                    backgroundColor: '#5be8e4',
                                    borderColor: borderColor,
                                    borderWidth: borderWidth,
                                    barPercentage: barPercentage,
                                    stack: 'Stack 0',
                                    datalabels: {
                                        align: 'center',
                                        anchor: 'center',
                                        color: 'white',
                                        display: function (context) {
                                            return context.dataset.data[context.dataIndex] > twentieth;
                                        },
                                        font: {
                                            weight: 'normal'
                                        },
                                        formatter: Math.round
                                    }
                                },
                                {
                                    label: dataStackedBar.bar2.label,
                                    data: dataStackedBar.bar2.value,
                                    backgroundColor: '#61c8d4',
                                    borderColor: borderColor,
                                    borderWidth: borderWidth,
                                    barPercentage: barPercentage,
                                    stack: 'Stack 0',
                                    datalabels: {
                                        align: 'center',
                                        anchor: 'center',
                                        color: 'white',
                                        display: function (context) {
                                            return context.dataset.data[context.dataIndex] > twentieth;
                                        },
                                        font: {
                                            weight: 'normal'
                                        },
                                        formatter: Math.round
                                    }
                                },
                                {
                                    label: dataStackedBar.bar1.label,
                                    data: dataStackedBar.bar1.value,
                                    backgroundColor: '#7bbee3',
                                    borderColor: borderColor,
                                    borderWidth: borderWidth,
                                    barPercentage: barPercentage,
                                    stack: 'Stack 0',
                                    datalabels: {
                                        align: 'center',
                                        anchor: 'center',
                                        color: 'white',
                                        display: function (context) {
                                            return context.dataset.data[context.dataIndex] > twentieth;
                                        },
                                        font: {
                                            weight: 'normal'
                                        },
                                        formatter: Math.round
                                    }
                                },
                                {
                                    label: dataStackedBar.bar0.label,
                                    data: dataStackedBar.bar0.value,
                                    backgroundColor: '#7ba0cf',
                                    borderColor: borderColor,
                                    borderWidth: borderWidth,
                                    barPercentage: barPercentage,
                                    stack: 'Stack 0',
                                    datalabels: {
                                        align: 'center',
                                        anchor: 'center',
                                        color: 'white',
                                        display: function (context) {
                                            return context.dataset.data[context.dataIndex] > twentieth;
                                        },
                                        font: {
                                            weight: 'normal'
                                        },
                                        formatter: Math.round
                                    }
                                },
                                {
                                    label: "Total",
                                    data: arrTotal,
                                    backgroundColor: 'transparent',
                                    stack: 'Stack 0',
                                    hiddenLegend: true,
                                },
                            ]
                        },
                        options: {
                            maintainAspectRatio: false,
                            legend: {
                                position: innerWidth < 1200 ? 'bottom' : 'right',
                                align: 'center',
                                fullWidth: true,
                                reverse: true,
                                labels: {
                                    boxWidth: 30,
                                    padding: 10,
                                    fontSize: innerWidth < 1200 ? 14 : 16,
                                    fontFamily: 'Roboto Light',
                                    filter: function (item, data) {
                                        return (!data.datasets[item.datasetIndex].hiddenLegend)
                                    }
                                }
                            },
                            animation: {
                                duration: 10,
                            },
                            tooltips: {
                                mode: 'label',
                                callbacks: {
                                    label: function (item, data) {
                                        if (!data.datasets[item.datasetIndex].hiddenLegend) {
                                            return data.datasets[item.datasetIndex].label + ": " + numberWithCommas(item.yLabel);
                                        }
                                    }
                                }
                            },
                            scales: {
                                xAxes: [{
                                    stacked: true,
                                    gridLines: {
                                        display: false,
                                    }
                                }],
                                yAxes: [{
                                    stacked: true,
                                    gridLines: {
                                        display: false,
                                    },
                                    ticks: {
                                        maxTicksLimit: 2,
                                        max: numPadding
                                    },
                                }]
                            },
                            plugins: {
                                datalabels: {
                                    formatter: (value, ctx) => {
                                        return ctx.chart.$totalizer.stacktotal[ctx.dataset.stack][ctx.dataIndex];
                                    },
                                    align: 'end',
                                    anchor: 'end',
                                    display: function (ctx) {
                                        return ctx.chart.$totalizer.utmost[ctx.dataset.stack] === ctx.datasetIndex;
                                    },
                                    color: '#000000',
                                    font: {
                                        size: 17,
                                        weight: 400
                                    }
                                }
                            }
                        },
                        plugins: [totalizer]
                    });

                    this.setState({
                        myChartRefCreation: myChartRefCreation,
                        currentCreation: dataStackedBar
                    });
                }
            }

        }).catch(err => {
            console.log(err);
        });

        Api.get('/reports/histogram-created-users?range=7d', true).then(resp => {

            if (resp.data) {
                this.setState({
                    dataCreation_weekly: resp.data,
                    disableWeekly: false
                });
            }

        }).catch(err => {
            console.log(err);
        });

        Api.get('/reports/histogram-created-users?range=month', true).then(resp => {

            if (resp.data) {
                this.setState({
                    dataCreation_monthly: resp.data,
                    disableMonthly: false
                });
            }

        }).catch(err => {
            console.log(err);
        });

        Api.get('/reports/user-registration-summary', true).then(resp => {

            if (resp.data) {
                this.setState({
                    totalUsers: this.kFormatter(resp.data.dataTolls.total),
                    dataTolls: resp.data.dataTolls
                }, () => this.__fetchDataToll());
            }

        }).catch(err => {
            console.log(err);
        });

        Api.get('/reports/onboarding-summary', true).then(resp => {

            if (resp.data) {

                let data = resp.data;

                let time;
                if (data.dataOnBoardingSummary.onBoardingAvgTime < 1500) {
                    time = this.convertMinsToHrsMins(data.dataOnBoardingSummary.onBoardingAvgTime);
                } else {
                    time = this.convertMinsToDaysHrsMins(data.dataOnBoardingSummary.onBoardingAvgTime);
                }

                this.setState({
                    onBoardingAvgTimeD: time[0],
                    onBoardingAvgTimeH: time[1],
                    onBoardingAvgTimeM: time[2],
                });

                _.forEach(data.dataOnBoardingSummary.completed.label, function (v, k) {
                    let splitStr = v.toLowerCase().split(' ');
                    for (let i = 0; i < splitStr.length; i++) {
                        splitStr[i] = splitStr[i].charAt(0).toUpperCase() + splitStr[i].substring(1);
                    }
                    data.dataOnBoardingSummary.completed.label[k] = splitStr.join(' ');

                    if (v === 'completed') {
                        data.dataOnBoardingSummary.completed.label[k] = 'Completion';
                    }

                });

                if (this.chartRefProgress.current) {
                    let myChartRef = this.chartRefProgress.current.getContext("2d");

                    new Chart(myChartRef, {
                        type: 'bar',
                        data: {
                            //Bring in data
                            labels: data.dataOnBoardingSummary.completed.label,
                            datasets: [
                                {
                                    label: "Incomplete",
                                    data: data.dataOnBoardingSummary.onProgress.data,
                                    borderColor: '#ffb347',
                                    backgroundColor: '#ffb347',
                                    hiddenLegend: true,
                                    datalabels: {
                                        color: 'transparent'
                                    }
                                },
                                {
                                    label: "Completed",
                                    data: data.dataOnBoardingSummary.completed.data,
                                    borderColor: '#779ecb',
                                    backgroundColor: '#779ecb',
                                    hiddenLegend: true,
                                    datalabels: {
                                        color: 'transparent'
                                    }
                                }
                            ]
                        },
                        options: {
                            maintainAspectRatio: false,
                            animation: {
                                duration: 10,
                            },
                            scales: {
                                xAxes: [{
                                    gridLines: {
                                        display: false,
                                    },
                                }],
                                yAxes: [{
                                    showLines: true,
                                    gridLines: {
                                        display: true,
                                    },
                                    ticks: {
                                        beginAtZero: true,
                                    },
                                }]
                            },
                            legend: {display: false}
                        }
                    });
                }
            }

        }).catch(err => {
            console.log(err);
        });

        Api.get('/reports/average-dollar-summary', true).then(resp => {

            if (resp.data) {
                this.setState({
                    dataAverageDollarSummary: resp.data.average_dollar_amount_per_account
                }, () => this.__fetchDataAverageDollarSummary());
            }

        }).catch(err => {
            console.log(err);
        });

        Api.get('/reports/total-payment-transaction', true).then(resp => {
            if (resp.data) {

                let dataStackedBar = resp.data.toll_payment_transaction.daily;

                this.setState({
                    dataTotalPaymentTransaction: resp.data.toll_payment_transaction,
                    currentTotalPaymentTransaction: resp.data.toll_payment_transaction.daily
                });

                let arrTotal = dataStackedBar.dates.map(x => 0);

                let tenth = parseInt(_.max(dataStackedBar.sum) * 0.1) + 1;
                let twentieth = parseInt(_.max(dataStackedBar.sum) * 0.2) - 1;

                if (twentieth === -1) {
                    twentieth = 0;
                }

                let numPadding = _.max(dataStackedBar.sum) + tenth;

                if (this.chartRefTotalPaymentTransaction.current) {
                    let myChartRefBar = this.chartRefTotalPaymentTransaction.current.getContext("2d");

                    let myChartRefCreation = new Chart(myChartRefBar, {
                        type: 'bar',
                        data: {
                            //Bring in data
                            labels: dataStackedBar.dates,
                            datasets: [
                                {
                                    label: dataStackedBar.applePay.label,
                                    data: dataStackedBar.applePay.value,
                                    backgroundColor: '#5be8e4',
                                    borderColor: borderColor,
                                    borderWidth: borderWidth,
                                    barPercentage: barPercentage,
                                    stack: 'Stack 0',
                                    datalabels: {
                                        align: 'center',
                                        anchor: 'center',
                                        color: 'white',
                                        display: function (context) {
                                            return context.dataset.data[context.dataIndex] > twentieth;
                                        },
                                        font: {
                                            weight: 'normal'
                                        },
                                        formatter: Math.round
                                    }
                                },
                                {
                                    label: dataStackedBar.googlePay.label,
                                    data: dataStackedBar.googlePay.value,
                                    backgroundColor: '#61c8d4',
                                    borderColor: borderColor,
                                    borderWidth: borderWidth,
                                    barPercentage: barPercentage,
                                    stack: 'Stack 0',
                                    datalabels: {
                                        align: 'center',
                                        anchor: 'center',
                                        color: 'white',
                                        display: function (context) {
                                            return context.dataset.data[context.dataIndex] > twentieth;
                                        },
                                        font: {
                                            weight: 'normal'
                                        },
                                        formatter: Math.round
                                    }
                                },
                                {
                                    label: dataStackedBar.creditCard.label,
                                    data: dataStackedBar.creditCard.value,
                                    backgroundColor: '#7bbee3',
                                    borderColor: borderColor,
                                    borderWidth: borderWidth,
                                    barPercentage: barPercentage,
                                    stack: 'Stack 0',
                                    datalabels: {
                                        align: 'center',
                                        anchor: 'center',
                                        color: 'white',
                                        display: function (context) {
                                            return context.dataset.data[context.dataIndex] > twentieth;
                                        },
                                        font: {
                                            weight: 'normal'
                                        },
                                        formatter: Math.round
                                    }
                                },
                                {
                                    label: dataStackedBar.ach.label,
                                    data: dataStackedBar.ach.value,
                                    backgroundColor: '#7ba0cf',
                                    borderColor: borderColor,
                                    borderWidth: borderWidth,
                                    barPercentage: barPercentage,
                                    stack: 'Stack 0',
                                    datalabels: {
                                        align: 'center',
                                        anchor: 'center',
                                        color: 'white',
                                        display: function (context) {
                                            return context.dataset.data[context.dataIndex] > twentieth;
                                        },
                                        font: {
                                            weight: 'normal'
                                        },
                                        formatter: Math.round
                                    }
                                },
                                {
                                    label: "Total",
                                    data: arrTotal,
                                    backgroundColor: 'transparent',
                                    stack: 'Stack 0',
                                    hiddenLegend: true,
                                },
                            ]
                        },
                        options: {
                            maintainAspectRatio: false,
                            legend: {
                                position: innerWidth < 1200 ? 'bottom' : 'right',
                                align: 'center',
                                fullWidth: true,
                                reverse: true,
                                labels: {
                                    boxWidth: 30,
                                    padding: 10,
                                    fontSize: innerWidth < 1200 ? 14 : 16,
                                    fontFamily: 'Roboto Light',
                                    filter: function (item, data) {
                                        return (!data.datasets[item.datasetIndex].hiddenLegend)
                                    }
                                }
                            },
                            animation: {
                                duration: 10,
                            },
                            tooltips: {
                                mode: 'label',
                                callbacks: {
                                    label: function (item, data) {
                                        if (!data.datasets[item.datasetIndex].hiddenLegend) {
                                            return data.datasets[item.datasetIndex].label + ": " + numberWithCommas(item.yLabel);
                                        }
                                    }
                                }
                            },
                            scales: {
                                xAxes: [{
                                    stacked: true,
                                    gridLines: {
                                        display: false,
                                    }
                                }],
                                yAxes: [{
                                    stacked: true,
                                    gridLines: {
                                        display: false,
                                    },
                                    ticks: {
                                        maxTicksLimit: 2,
                                        max: numPadding
                                    },
                                }]
                            },
                            plugins: {
                                datalabels: {
                                    formatter: (value, ctx) => {
                                        return ctx.chart.$totalizer.stacktotal[ctx.dataset.stack][ctx.dataIndex];
                                    },
                                    align: 'end',
                                    anchor: 'end',
                                    display: function (ctx) {
                                        return ctx.chart.$totalizer.utmost[ctx.dataset.stack] === ctx.datasetIndex;
                                    },
                                    color: '#000000',
                                    font: {
                                        size: 17,
                                        weight: 400
                                    }
                                }
                            }
                        },
                        plugins: [totalizer]
                    });

                    this.setState({
                        myChartRefTotalPaymentTransaction: myChartRefCreation,
                    });
                }
            }

        }).catch(err => {
            console.log(err);
        });

        Api.get('/reports/total-payment-volumes', true).then(resp => {
            if (resp) {

                let dataStackedBar = resp.total_payment_volumes.daily;

                this.setState({
                    dataTotalPaymentVolume: resp.total_payment_volumes,
                    currentTotalPaymentVolume: resp.total_payment_volumes.daily
                });

                let arrTotal = dataStackedBar.dates.map(x => 0);

                let tenth = parseInt(_.max(dataStackedBar.sum) * 0.1) + 1;
                let twentieth = parseInt(_.max(dataStackedBar.sum) * 0.2) - 1;

                if (twentieth === -1) {
                    twentieth = 0;
                }

                let numPadding = _.max(dataStackedBar.sum) + tenth;

                if (this.chartRefTotalPaymentVolume.current) {
                    let myChartRefBar = this.chartRefTotalPaymentVolume.current.getContext("2d");

                    let myChartRefCreation = new Chart(myChartRefBar, {
                        type: 'bar',
                        data: {
                            //Bring in data
                            labels: dataStackedBar.dates,
                            datasets: [
                                {
                                    label: dataStackedBar.Phone.label,
                                    data: dataStackedBar.Phone.value,
                                    backgroundColor: '#5be8e4',
                                    borderColor: borderColor,
                                    borderWidth: borderWidth,
                                    barPercentage: barPercentage,
                                    stack: 'Stack 0',
                                    datalabels: {
                                        align: 'center',
                                        anchor: 'center',
                                        color: 'white',
                                        display: function (context) {
                                            return context.dataset.data[context.dataIndex] > twentieth;
                                        },
                                        font: {
                                            weight: 'normal'
                                        },
                                        formatter: Math.round
                                    }
                                },
                                {
                                    label: dataStackedBar.Email.label,
                                    data: dataStackedBar.Email.value,
                                    backgroundColor: '#61c8d4',
                                    borderColor: borderColor,
                                    borderWidth: borderWidth,
                                    barPercentage: barPercentage,
                                    stack: 'Stack 0',
                                    datalabels: {
                                        align: 'center',
                                        anchor: 'center',
                                        color: 'white',
                                        display: function (context) {
                                            return context.dataset.data[context.dataIndex] > twentieth;
                                        },
                                        font: {
                                            weight: 'normal'
                                        },
                                        formatter: Math.round
                                    }
                                },
                                {
                                    label: dataStackedBar.Google.label,
                                    data: dataStackedBar.Google.value,
                                    backgroundColor: '#7bbee3',
                                    borderColor: borderColor,
                                    borderWidth: borderWidth,
                                    barPercentage: barPercentage,
                                    stack: 'Stack 0',
                                    datalabels: {
                                        align: 'center',
                                        anchor: 'center',
                                        color: 'white',
                                        display: function (context) {
                                            return context.dataset.data[context.dataIndex] > twentieth;
                                        },
                                        font: {
                                            weight: 'normal'
                                        },
                                        formatter: Math.round
                                    }
                                },
                                {
                                    label: dataStackedBar.Facebook.label,
                                    data: dataStackedBar.Facebook.value,
                                    backgroundColor: '#7ba0cf',
                                    borderColor: borderColor,
                                    borderWidth: borderWidth,
                                    barPercentage: barPercentage,
                                    stack: 'Stack 0',
                                    datalabels: {
                                        align: 'center',
                                        anchor: 'center',
                                        color: 'white',
                                        display: function (context) {
                                            return context.dataset.data[context.dataIndex] > twentieth;
                                        },
                                        font: {
                                            weight: 'normal'
                                        },
                                        formatter: Math.round
                                    }
                                },
                                {
                                    label: "Total",
                                    data: arrTotal,
                                    backgroundColor: 'transparent',
                                    stack: 'Stack 0',
                                    hiddenLegend: true,
                                },
                            ]
                        },
                        options: {
                            maintainAspectRatio: false,
                            legend: {
                                position: innerWidth < 1200 ? 'bottom' : 'right',
                                align: 'center',
                                fullWidth: true,
                                reverse: true,
                                labels: {
                                    boxWidth: 30,
                                    padding: 10,
                                    fontSize: innerWidth < 1200 ? 14 : 16,
                                    fontFamily: 'Roboto Light',
                                    filter: function (item, data) {
                                        return (!data.datasets[item.datasetIndex].hiddenLegend)
                                    }
                                }
                            },
                            animation: {
                                duration: 10,
                            },
                            tooltips: {
                                mode: 'label',
                                callbacks: {
                                    label: function (item, data) {
                                        if (!data.datasets[item.datasetIndex].hiddenLegend) {
                                            return data.datasets[item.datasetIndex].label + ": " + numberWithCommas(item.yLabel);
                                        }
                                    }
                                }
                            },
                            scales: {
                                xAxes: [{
                                    stacked: true,
                                    gridLines: {
                                        display: false,
                                    }
                                }],
                                yAxes: [{
                                    stacked: true,
                                    gridLines: {
                                        display: false,
                                    },
                                    ticks: {
                                        maxTicksLimit: 2,
                                        max: numPadding
                                    },
                                }]
                            },
                            plugins: {
                                datalabels: {
                                    formatter: (value, ctx) => {
                                        return ctx.chart.$totalizer.stacktotal[ctx.dataset.stack][ctx.dataIndex];
                                    },
                                    align: 'end',
                                    anchor: 'end',
                                    display: function (ctx) {
                                        return ctx.chart.$totalizer.utmost[ctx.dataset.stack] === ctx.datasetIndex;
                                    },
                                    color: '#000000',
                                    font: {
                                        size: 17,
                                        weight: 400
                                    }
                                }
                            }
                        },
                        plugins: [totalizer]
                    });

                    this.setState({
                        myChartRefTotalPaymentVolume: myChartRefCreation,
                    });
                }
            }

        }).catch(err => {
            console.log(err);
        });

        Api.get('/reports/average-toll-cost', true).then(resp => {

            if (resp.data) {
                this.setState({
                    dataAverageTollTransactionCost: resp.data.average_toll_cost
                }, () => this.__fetchDataAverageTollTransactionCost());
            }

        }).catch(err => {
            console.log(err);
        });

        Api.get('/reports/total-payment-gateway', true).then(resp => {

            if (resp.total_payment_gateway) {
                this.setState({
                    dataTotalPaymentGateway: resp.total_payment_gateway
                }, () => this.__fetchDataTotalPaymentGateway());
            }

        }).catch(err => {
            console.log(err);
        });

        Api.get('/reports/toll-activity', true).then(resp => {

            if (resp.toll_activity) {
                this.setState({
                    dataTollActivity: resp.toll_activity
                }, () => this.__fetchDataTollActivity());
            }

        }).catch(err => {
            console.log(err);
        });

        Api.get('/reports/prepay-amount-by-account', true).then(resp => {

            if (resp.data.prepay_amount_creation_type) {

                let dataStackedBar = resp.data.prepay_amount_creation_type.daily;

                this.setState({
                    dataPrepayAmount: resp.data.prepay_amount_creation_type,
                    currentPrepayAmount: resp.data.prepay_amount_creation_type.daily
                });

                let arrTotal = dataStackedBar.dates.map(x => 0);

                let tenth = parseInt(_.max(dataStackedBar.sum) * 0.1) + 1;
                let twentieth = parseInt(_.max(dataStackedBar.sum) * 0.2) - 1;

                if (twentieth === -1) {
                    twentieth = 0;
                }

                let numPadding = _.max(dataStackedBar.sum) + tenth;

                if (this.chartRefPrepayAmount.current) {
                    let myChartRefBar = this.chartRefPrepayAmount.current.getContext("2d");

                    let myChartRefCreation = new Chart(myChartRefBar, {
                        type: 'bar',
                        data: {
                            //Bring in data
                            labels: dataStackedBar.dates,
                            datasets: [
                                {
                                    label: dataStackedBar.Phone.label,
                                    data: dataStackedBar.Phone.value,
                                    backgroundColor: '#5be8e4',
                                    borderColor: borderColor,
                                    borderWidth: borderWidth,
                                    barPercentage: barPercentage,
                                    stack: 'Stack 0',
                                    datalabels: {
                                        align: 'center',
                                        anchor: 'center',
                                        color: 'white',
                                        display: function (context) {
                                            return context.dataset.data[context.dataIndex] > twentieth;
                                        },
                                        font: {
                                            weight: 'normal'
                                        },
                                        formatter: Math.round
                                    }
                                },
                                {
                                    label: dataStackedBar.Email.label,
                                    data: dataStackedBar.Email.value,
                                    backgroundColor: '#61c8d4',
                                    borderColor: borderColor,
                                    borderWidth: borderWidth,
                                    barPercentage: barPercentage,
                                    stack: 'Stack 0',
                                    datalabels: {
                                        align: 'center',
                                        anchor: 'center',
                                        color: 'white',
                                        display: function (context) {
                                            return context.dataset.data[context.dataIndex] > twentieth;
                                        },
                                        font: {
                                            weight: 'normal'
                                        },
                                        formatter: Math.round
                                    }
                                },
                                {
                                    label: dataStackedBar.Google.label,
                                    data: dataStackedBar.Google.value,
                                    backgroundColor: '#7bbee3',
                                    borderColor: borderColor,
                                    borderWidth: borderWidth,
                                    barPercentage: barPercentage,
                                    stack: 'Stack 0',
                                    datalabels: {
                                        align: 'center',
                                        anchor: 'center',
                                        color: 'white',
                                        display: function (context) {
                                            return context.dataset.data[context.dataIndex] > twentieth;
                                        },
                                        font: {
                                            weight: 'normal'
                                        },
                                        formatter: Math.round
                                    }
                                },
                                {
                                    label: dataStackedBar.Facebook.label,
                                    data: dataStackedBar.Facebook.value,
                                    backgroundColor: '#7ba0cf',
                                    borderColor: borderColor,
                                    borderWidth: borderWidth,
                                    barPercentage: barPercentage,
                                    stack: 'Stack 0',
                                    datalabels: {
                                        align: 'center',
                                        anchor: 'center',
                                        color: 'white',
                                        display: function (context) {
                                            return context.dataset.data[context.dataIndex] > twentieth;
                                        },
                                        font: {
                                            weight: 'normal'
                                        },
                                        formatter: Math.round
                                    }
                                },
                                {
                                    label: "Total",
                                    data: arrTotal,
                                    backgroundColor: 'transparent',
                                    stack: 'Stack 0',
                                    hiddenLegend: true,
                                },
                            ]
                        },
                        options: {
                            maintainAspectRatio: false,
                            legend: {
                                position: innerWidth < 1200 ? 'bottom' : 'right',
                                align: 'center',
                                fullWidth: true,
                                reverse: true,
                                labels: {
                                    boxWidth: 30,
                                    padding: 10,
                                    fontSize: innerWidth < 1200 ? 14 : 16,
                                    fontFamily: 'Roboto Light',
                                    filter: function (item, data) {
                                        return (!data.datasets[item.datasetIndex].hiddenLegend)
                                    }
                                }
                            },
                            animation: {
                                duration: 10,
                            },
                            tooltips: {
                                mode: 'label',
                                callbacks: {
                                    label: function (item, data) {
                                        if (!data.datasets[item.datasetIndex].hiddenLegend) {
                                            return data.datasets[item.datasetIndex].label + ": " + numberWithCommas(item.yLabel);
                                        }
                                    }
                                }
                            },
                            scales: {
                                xAxes: [{
                                    stacked: true,
                                    gridLines: {
                                        display: false,
                                    }
                                }],
                                yAxes: [{
                                    stacked: true,
                                    gridLines: {
                                        display: false,
                                    },
                                    ticks: {
                                        maxTicksLimit: 2,
                                        max: numPadding
                                    },
                                }]
                            },
                            plugins: {
                                datalabels: {
                                    formatter: (value, ctx) => {
                                        return parseFloat(ctx.chart.$totalizer.stacktotal[ctx.dataset.stack][ctx.dataIndex]).toFixed(2);
                                    },
                                    align: 'end',
                                    anchor: 'end',
                                    display: function (ctx) {
                                        return ctx.chart.$totalizer.utmost[ctx.dataset.stack] === ctx.datasetIndex;
                                    },
                                    color: '#000000',
                                    font: {
                                        size: 17,
                                        weight: 400
                                    }
                                }
                            }
                        },
                        plugins: [totalizer]
                    });

                    this.setState({
                        myChartRefPrepayAmount: myChartRefCreation,
                    });
                }
            }

        }).catch(err => {
            console.log(err);
        });

        Api.get('/reports/toll-type-tag', true).then(resp => {

            if (resp.data) {

                let data = [];

                if (Array.isArray(resp.data.toll_type_tag.daily)) {

                    if (Array.isArray(resp.data.toll_type_tag.weekly)) {

                        if (Array.isArray(resp.data.toll_type_tag.monthly)) {

                        } else {
                            data = resp.data.toll_type_tag.monthly;
                        }

                    } else {
                        data = resp.data.toll_type_tag.weekly;
                    }

                } else {
                    data = resp.data.toll_type_tag.daily;
                }

                if (this.chartRefTollTransactionType.current) {
                    let myChartRef = this.chartRefTollTransactionType.current.getContext("2d");

                    let myChartRefCreation = new Chart(myChartRef, {
                        type: 'bar',
                        data: {
                            //Bring in data
                            labels: Array.isArray(data) ? data : data.label,
                            datasets: [
                                {
                                    label: "Business",
                                    data: Array.isArray(data) ? data : data.Business.data,
                                    borderColor: '#ffb347',
                                    backgroundColor: '#ffb347',
                                    hiddenLegend: true,
                                    datalabels: {
                                        color: 'transparent'
                                    }
                                },
                                {
                                    label: "Personal",
                                    data: Array.isArray(data) ? data : data.Personal.data,
                                    borderColor: '#779ecb',
                                    backgroundColor: '#779ecb',
                                    hiddenLegend: true,
                                    datalabels: {
                                        color: 'transparent'
                                    }
                                }
                            ]
                        },
                        options: {
                            maintainAspectRatio: false,
                            animation: {
                                duration: 10,
                            },
                            scales: {
                                xAxes: [{
                                    gridLines: {
                                        display: false,
                                    },
                                }],
                                yAxes: [{
                                    showLines: true,
                                    gridLines: {
                                        display: true,
                                    },
                                    ticks: {
                                        beginAtZero: true,
                                        precision: 0
                                    },
                                }]
                            }
                        }
                    });

                    this.setState({
                        dataTollTransactionType: resp.data.toll_type_tag,
                        currentTollTransactionType: resp.data.toll_type_tag.daily,
                        myChartRefTollTransactionType: myChartRefCreation,
                    }, () => {

                        this.buildChartTollTransactionType(this.state.myChartRefTollTransactionType, this.state.currentTollTransactionType);

                    });
                }
            }

        }).catch(err => {
            console.log(err);
        });

        Api.get('/reports/average-toll-account', true).then(resp => {

            if (resp.data) {
                this.setState({
                    dataAverageTollAccount: resp.data.average_toll_use_per_account
                }, () => this.__fetchDataAverageTollAccount());
            }

        }).catch(err => {
            console.log(err);
        });

    }

    buildChart(chart, current) {

        chart.data.labels = current.label;
        chart.data.datasets = [
            {
                label: "Total Users",
                data: current.data,
                borderColor: '#008299',
                backgroundColor: 'rgba(0, 130, 153, 0.7)',
                hiddenLegend: true,
                datalabels: {
                    color: 'transparent'
                }
            }
        ];
        chart.update();

    };

    buildChartCreation(chart, dataStackedBar) {

        let innerWidth = window.innerWidth;

        let arrTotal = dataStackedBar.barDate.map(x => 0);

        let tenth = parseInt(_.max(dataStackedBar.SumPerdates) * 0.1) + 1;
        let twentieth = parseInt(_.max(dataStackedBar.SumPerdates) * 0.2) - 1;

        if (twentieth === -1) {
            twentieth = 0;
        }

        let numPadding = _.max(dataStackedBar.SumPerdates) + tenth;

        chart.data.labels = dataStackedBar.barDate;
        chart.data.datasets = [
            {
                label: dataStackedBar.bar3.label,
                data: dataStackedBar.bar3.value,
                backgroundColor: '#5be8e4',
                borderColor: borderColor,
                borderWidth: borderWidth,
                barPercentage: barPercentage,
                stack: 'Stack 0',
                datalabels: {
                    align: 'center',
                    anchor: 'center',
                    color: 'white',
                    display: function (context) {
                        return context.dataset.data[context.dataIndex] > twentieth;
                    },
                    font: {
                        weight: 'normal'
                    },
                    formatter: Math.round
                }
            },
            {
                label: dataStackedBar.bar2.label,
                data: dataStackedBar.bar2.value,
                backgroundColor: '#61c8d4',
                borderColor: borderColor,
                borderWidth: borderWidth,
                barPercentage: barPercentage,
                stack: 'Stack 0',
                datalabels: {
                    align: 'center',
                    anchor: 'center',
                    color: 'white',
                    display: function (context) {
                        return context.dataset.data[context.dataIndex] > twentieth;
                    },
                    font: {
                        weight: 'normal'
                    },
                    formatter: Math.round
                }
            },
            {
                label: dataStackedBar.bar1.label,
                data: dataStackedBar.bar1.value,
                backgroundColor: '#7bbee3',
                borderColor: borderColor,
                borderWidth: borderWidth,
                barPercentage: barPercentage,
                stack: 'Stack 0',
                datalabels: {
                    align: 'center',
                    anchor: 'center',
                    color: 'white',
                    display: function (context) {
                        return context.dataset.data[context.dataIndex] > twentieth;
                    },
                    font: {
                        weight: 'normal'
                    },
                    formatter: Math.round
                }
            },
            {
                label: dataStackedBar.bar0.label,
                data: dataStackedBar.bar0.value,
                backgroundColor: '#7ba0cf',
                borderColor: borderColor,
                borderWidth: borderWidth,
                barPercentage: barPercentage,
                stack: 'Stack 0',
                datalabels: {
                    align: 'center',
                    anchor: 'center',
                    color: 'white',
                    display: function (context) {
                        return context.dataset.data[context.dataIndex] > twentieth;
                    },
                    font: {
                        weight: 'normal'
                    },
                    formatter: Math.round
                }
            },
            {
                label: "Total",
                data: arrTotal,
                backgroundColor: 'transparent',
                stack: 'Stack 0',
                hiddenLegend: true,
            },
        ];
        chart.options = {
            maintainAspectRatio: false,
            legend: {
                position: innerWidth < 1200 ? 'bottom' : 'right',
                align: 'center',
                fullWidth: true,
                reverse: true,
                labels: {
                    boxWidth: 30,
                    padding: 10,
                    fontSize: innerWidth < 1200 ? 14 : 16,
                    fontFamily: 'Roboto Light',
                    filter: function (item, data) {
                        return (!data.datasets[item.datasetIndex].hiddenLegend)
                    }
                }
            },
            animation: {
                duration: 10,
            },
            tooltips: {
                mode: 'label',
                callbacks: {
                    label: function (item, data) {
                        if (!data.datasets[item.datasetIndex].hiddenLegend) {
                            return data.datasets[item.datasetIndex].label + ": " + numberWithCommas(item.yLabel);
                        }
                    }
                }
            },
            scales: {
                xAxes: [{
                    stacked: true,
                    gridLines: {
                        display: false,
                    }
                }],
                yAxes: [{
                    stacked: true,
                    gridLines: {
                        display: false,
                    },
                    ticks: {
                        maxTicksLimit: 2,
                        max: numPadding
                    },
                }]
            },
            plugins: {
                datalabels: {
                    formatter: (value, ctx) => {
                        return ctx.chart.$totalizer.stacktotal[ctx.dataset.stack][ctx.dataIndex];
                    },
                    align: 'end',
                    anchor: 'end',
                    display: function (ctx) {
                        return ctx.chart.$totalizer.utmost[ctx.dataset.stack] === ctx.datasetIndex;
                    },
                    color: '#000000',
                    font: {
                        size: 17,
                        weight: 400
                    }
                }
            }
        };
        chart.update();

    };

    __fetchDataToll = () => {

        if (this.chartRef.current) {
            let myChartRef = this.chartRef.current.getContext("2d");

            let myChartRefAcc = new Chart(myChartRef, {
                type: 'bar',
                data: {
                    //Bring in data
                    labels: '',
                    datasets: []
                },
                options: {
                    maintainAspectRatio: false,
                    animation: {
                        duration: 10,
                    },
                    scales: {
                        xAxes: [{
                            gridLines: {
                                display: false,
                            },
                        }],
                        yAxes: [{
                            showLines: true,
                            gridLines: {
                                display: true,
                            },
                            ticks: {
                                precision: 0
                            }
                        }]
                    },
                    legend: {display: false}
                }
            });

            this.setState({
                    myChartRefAcc: myChartRefAcc,
                    currentToll: this.state.dataTolls.daily
                }, () => {

                    this.buildChart(this.state.myChartRefAcc, this.state.currentToll);

                }
            );

        }

    };

    __fetchDataAverageDollarSummary = () => {

        if (this.chartRefAverageDollarSummary.current) {
            let myChartRef = this.chartRefAverageDollarSummary.current.getContext("2d");

            let myChartRefAcc = new Chart(myChartRef, {
                type: 'line',
                data: {
                    //Bring in data
                    labels: '',
                    datasets: [],
                    ttps: {}
                },
                options: {
                    maintainAspectRatio: false,
                    animation: {
                        duration: 10,
                    },
                    scales: {
                        borderDashOffset:0.5,
                        xAxes: [{
                            gridLines: {
                                display: false,
                            },
                        }],
                        yAxes: [{
                            showLines: true,
                            gridLines: {
                                display: true,
                            },
                            ticks: {
                                precision: 2,
                                //beginAtZero: true,
                            }
                        }]
                    },
                    legend: {display: false},
                    tooltips: {
                        bodyFontSize: 16,
                        mode: 'label',
                        custom: function (tooltip) {
                            if (!tooltip) return;
                            // disable displaying the color box;
                            tooltip.displayColors = false;
                        },
                        callbacks: {
                            title: function () {
                            },
                            label: function (item, data) {

                                var obj = data.datasets[item.datasetIndex].ttps[item.index];

                                var acc = obj.total > 1 ? ' Accounts' : ' Account';

                                var text = [];
                                text.push(obj.date + ': ' + obj.total + acc);
                                text.push('Dollar Amount: $' + obj.sum);
                                text.push('Average Per Account: $' + obj.avg);
                                text.push('High Account: $' + obj.max);
                                text.push('Low Account: $' + obj.min);

                                return text;

                            }
                        }
                    },
                }
            });

            this.setState({
                    myChartRefAverageDollarSummary: myChartRefAcc,
                    currentAverageDollarSummary: this.state.dataAverageDollarSummary.daily
                }, () => {

                    this.buildChartAverageDollarSummary(this.state.myChartRefAverageDollarSummary, this.state.currentAverageDollarSummary);

                }
            );

        }

    };

    __fetchDataAverageTollAccount = () => {

        if (this.chartRefAverageTollAccount.current) {
            let myChartRef = this.chartRefAverageTollAccount.current.getContext("2d");

            let myChartRefAcc = new Chart(myChartRef, {
                type: 'line',
                data: {
                    //Bring in data
                    labels: '',
                    datasets: [],
                    ttps: {}
                },
                options: {
                    maintainAspectRatio: false,
                    animation: {
                        duration: 10,
                    },
                    scales: {
                        xAxes: [{
                            gridLines: {
                                display: false,
                            },
                        }],
                        yAxes: [{
                            showLines: true,
                            gridLines: {
                                display: true,
                            },
                            ticks: {
                                precision: 0,
                                beginAtZero: true,
                            }
                        }]
                    },
                    legend: {display: false},
                    tooltips: {
                        bodyFontSize: 16,
                        mode: 'label',
                        custom: function (tooltip) {
                            if (!tooltip) return;
                            // disable displaying the color box;
                            tooltip.displayColors = false;
                        },
                        callbacks: {
                            title: function () {
                            },
                            label: function (item, data) {

                                var obj = data.datasets[item.datasetIndex].ttps[item.index];

                                var text = [];
                                text.push(obj.date);
                                text.push('Average Toll use per user: ' + obj.avg);
                                text.push('Total Toll use: ' + obj.total);
                                text.push('Highest Toll use: ' + obj.max);
                                text.push('Lowest Toll use: ' + obj.min);

                                return text;

                            }
                        }
                    },
                }
            });

            this.setState({
                    myChartRefAverageTollAccount: myChartRefAcc,
                    currentAverageTollAccount: this.state.dataAverageTollAccount.daily
                }, () => {

                    this.buildChartAverageDollarSummary(this.state.myChartRefAverageTollAccount, this.state.currentAverageTollAccount);

                }
            );

        }

    };

    buildChartAverageDollarSummary(chart, current) {

        chart.data.labels = current.label;
        var currentData = current.tooltip === undefined ? [] : current.tooltip.map(value=>{
            return (
                value.avg
            )
        })

        chart.data.datasets = [
            {
                label: "Average Dollar Summary",
                data: currentData, //current.data,
                ttps: current.tooltip,
                borderColor: '#008299',
                backgroundColor: '#008299',
                fill: false,
                hiddenLegend: true,
                datalabels: {
                    color: 'transparent'
                }
            }
        ];
        chart.update();

    };

    buildChartAverageTollAccount(chart, current) {

        chart.data.labels = current.label;
        chart.data.datasets = [
            {
                label: "Average Toll Use Per Account",
                data: current.data,
                ttps: current.tooltip,
                borderColor: '#008299',
                backgroundColor: '#008299',
                fill: false,
                hiddenLegend: true,
                datalabels: {
                    color: 'transparent'
                }
            }
        ];
        chart.update();

    };

    buildChartTotalPaymentTransaction(chart, dataStackedBar) {

        let innerWidth = window.innerWidth;

        let arrTotal = dataStackedBar.dates.map(x => 0);

        let tenth = parseInt(_.max(dataStackedBar.sum) * 0.1) + 1;
        let twentieth = parseInt(_.max(dataStackedBar.sum) * 0.2) - 1;

        if (twentieth === -1) {
            twentieth = 0;
        }

        let numPadding = _.max(dataStackedBar.sum) + tenth;

        chart.data.labels = dataStackedBar.dates;
        chart.data.datasets = [
            {
                label: dataStackedBar.applePay.label,
                data: dataStackedBar.applePay.value,
                backgroundColor: '#5be8e4',
                borderColor: borderColor,
                borderWidth: borderWidth,
                barPercentage: barPercentage,
                stack: 'Stack 0',
                datalabels: {
                    align: 'center',
                    anchor: 'center',
                    color: 'white',
                    display: function (context) {
                        return context.dataset.data[context.dataIndex] > twentieth;
                    },
                    font: {
                        weight: 'normal'
                    },
                    formatter: Math.round
                }
            },
            {
                label: dataStackedBar.googlePay.label,
                data: dataStackedBar.googlePay.value,
                backgroundColor: '#61c8d4',
                borderColor: borderColor,
                borderWidth: borderWidth,
                barPercentage: barPercentage,
                stack: 'Stack 0',
                datalabels: {
                    align: 'center',
                    anchor: 'center',
                    color: 'white',
                    display: function (context) {
                        return context.dataset.data[context.dataIndex] > twentieth;
                    },
                    font: {
                        weight: 'normal'
                    },
                    formatter: Math.round
                }
            },
            {
                label: dataStackedBar.creditCard.label,
                data: dataStackedBar.creditCard.value,
                backgroundColor: '#7bbee3',
                borderColor: borderColor,
                borderWidth: borderWidth,
                barPercentage: barPercentage,
                stack: 'Stack 0',
                datalabels: {
                    align: 'center',
                    anchor: 'center',
                    color: 'white',
                    display: function (context) {
                        return context.dataset.data[context.dataIndex] > twentieth;
                    },
                    font: {
                        weight: 'normal'
                    },
                    formatter: Math.round
                }
            },
            {
                label: dataStackedBar.ach.label,
                data: dataStackedBar.ach.value,
                backgroundColor: '#7ba0cf',
                borderColor: borderColor,
                borderWidth: borderWidth,
                barPercentage: barPercentage,
                stack: 'Stack 0',
                datalabels: {
                    align: 'center',
                    anchor: 'center',
                    color: 'white',
                    display: function (context) {
                        return context.dataset.data[context.dataIndex] > twentieth;
                    },
                    font: {
                        weight: 'normal'
                    },
                    formatter: Math.round
                }
            },
            {
                label: "Total",
                data: arrTotal,
                backgroundColor: 'transparent',
                stack: 'Stack 0',
                hiddenLegend: true,
            },
        ];
        chart.options = {
            maintainAspectRatio: false,
            legend: {
                position: innerWidth < 1200 ? 'bottom' : 'right',
                align: 'center',
                fullWidth: true,
                reverse: true,
                labels: {
                    boxWidth: 30,
                    padding: 10,
                    fontSize: innerWidth < 1200 ? 14 : 16,
                    fontFamily: 'Roboto Light',
                    filter: function (item, data) {
                        return (!data.datasets[item.datasetIndex].hiddenLegend)
                    }
                }
            },
            animation: {
                duration: 10,
            },
            tooltips: {
                mode: 'label',
                callbacks: {
                    label: function (item, data) {
                        if (!data.datasets[item.datasetIndex].hiddenLegend) {
                            return data.datasets[item.datasetIndex].label + ": " + numberWithCommas(item.yLabel);
                        }
                    }
                }
            },
            scales: {
                xAxes: [{
                    stacked: true,
                    gridLines: {
                        display: false,
                    }
                }],
                yAxes: [{
                    stacked: true,
                    gridLines: {
                        display: false,
                    },
                    ticks: {
                        maxTicksLimit: 2,
                        max: numPadding
                    },
                }]
            },
            plugins: {
                datalabels: {
                    formatter: (value, ctx) => {
                        return ctx.chart.$totalizer.stacktotal[ctx.dataset.stack][ctx.dataIndex];
                    },
                    align: 'end',
                    anchor: 'end',
                    display: function (ctx) {
                        return ctx.chart.$totalizer.utmost[ctx.dataset.stack] === ctx.datasetIndex;
                    },
                    color: '#000000',
                    font: {
                        size: 17,
                        weight: 400
                    }
                }
            }
        };
        chart.update();

    };

    buildChartTotalPaymentVolume(chart, dataStackedBar) {

        let innerWidth = window.innerWidth;

        let arrTotal = dataStackedBar.dates.map(x => 0);

        let tenth = parseInt(_.max(dataStackedBar.sum) * 0.1) + 1;
        let twentieth = parseInt(_.max(dataStackedBar.sum) * 0.2) - 1;

        if (twentieth === -1) {
            twentieth = 0;
        }

        let numPadding = _.max(dataStackedBar.sum) + tenth;

        chart.data.labels = dataStackedBar.dates;
        chart.data.datasets = [
            {
                label: dataStackedBar.Phone.label,
                data: dataStackedBar.Phone.value,
                backgroundColor: '#5be8e4',
                borderColor: borderColor,
                borderWidth: borderWidth,
                barPercentage: barPercentage,
                stack: 'Stack 0',
                datalabels: {
                    align: 'center',
                    anchor: 'center',
                    color: 'white',
                    display: function (context) {
                        return context.dataset.data[context.dataIndex] > twentieth;
                    },
                    font: {
                        weight: 'normal'
                    },
                    formatter: Math.round
                }
            },
            {
                label: dataStackedBar.Email.label,
                data: dataStackedBar.Email.value,
                backgroundColor: '#61c8d4',
                borderColor: borderColor,
                borderWidth: borderWidth,
                barPercentage: barPercentage,
                stack: 'Stack 0',
                datalabels: {
                    align: 'center',
                    anchor: 'center',
                    color: 'white',
                    display: function (context) {
                        return context.dataset.data[context.dataIndex] > twentieth;
                    },
                    font: {
                        weight: 'normal'
                    },
                    formatter: Math.round
                }
            },
            {
                label: dataStackedBar.Google.label,
                data: dataStackedBar.Google.value,
                backgroundColor: '#7bbee3',
                borderColor: borderColor,
                borderWidth: borderWidth,
                barPercentage: barPercentage,
                stack: 'Stack 0',
                datalabels: {
                    align: 'center',
                    anchor: 'center',
                    color: 'white',
                    display: function (context) {
                        return context.dataset.data[context.dataIndex] > twentieth;
                    },
                    font: {
                        weight: 'normal'
                    },
                    formatter: Math.round
                }
            },
            {
                label: dataStackedBar.Facebook.label,
                data: dataStackedBar.Facebook.value,
                backgroundColor: '#7ba0cf',
                borderColor: borderColor,
                borderWidth: borderWidth,
                barPercentage: barPercentage,
                stack: 'Stack 0',
                datalabels: {
                    align: 'center',
                    anchor: 'center',
                    color: 'white',
                    display: function (context) {
                        return context.dataset.data[context.dataIndex] > twentieth;
                    },
                    font: {
                        weight: 'normal'
                    },
                    formatter: Math.round
                }
            },
            {
                label: "Total",
                data: arrTotal,
                backgroundColor: 'transparent',
                stack: 'Stack 0',
                hiddenLegend: true,
            },
        ];
        chart.options = {
            maintainAspectRatio: false,
            legend: {
                position: innerWidth < 1200 ? 'bottom' : 'right',
                align: 'center',
                fullWidth: true,
                reverse: true,
                labels: {
                    boxWidth: 30,
                    padding: 10,
                    fontSize: innerWidth < 1200 ? 14 : 16,
                    fontFamily: 'Roboto Light',
                    filter: function (item, data) {
                        return (!data.datasets[item.datasetIndex].hiddenLegend)
                    }
                }
            },
            animation: {
                duration: 10,
            },
            tooltips: {
                mode: 'label',
                callbacks: {
                    label: function (item, data) {
                        if (!data.datasets[item.datasetIndex].hiddenLegend) {
                            return data.datasets[item.datasetIndex].label + ": " + numberWithCommas(item.yLabel);
                        }
                    }
                }
            },
            scales: {
                xAxes: [{
                    stacked: true,
                    gridLines: {
                        display: false,
                    }
                }],
                yAxes: [{
                    stacked: true,
                    gridLines: {
                        display: false,
                    },
                    ticks: {
                        maxTicksLimit: 2,
                        max: numPadding
                    },
                }]
            },
            plugins: {
                datalabels: {
                    formatter: (value, ctx) => {
                        return ctx.chart.$totalizer.stacktotal[ctx.dataset.stack][ctx.dataIndex];
                    },
                    align: 'end',
                    anchor: 'end',
                    display: function (ctx) {
                        return ctx.chart.$totalizer.utmost[ctx.dataset.stack] === ctx.datasetIndex;
                    },
                    color: '#000000',
                    font: {
                        size: 17,
                        weight: 400
                    }
                }
            }
        };
        chart.update();

    };

    __fetchDataAverageTollTransactionCost = () => {

        if (this.chartRefAverageTollTransactionCost.current) {
            let myChartRef = this.chartRefAverageTollTransactionCost.current.getContext("2d");

            let myChartRefAcc = new Chart(myChartRef, {
                type: 'bar',
                data: {
                    //Bring in data
                    labels: '',
                    datasets: [],
                    ttps: {}
                },
                options: {
                    maintainAspectRatio: false,
                    animation: {
                        duration: 10,
                    },
                    scales: {
                        xAxes: [{
                            gridLines: {
                                display: false,
                            },
                        }],
                        yAxes: [{
                            showLines: true,
                            gridLines: {
                                display: true,
                            },
                            ticks: {
                                precision: 0,
                                beginAtZero: true,
                            }
                        }]
                    },
                    legend: {display: false},
                    tooltips: {
                        bodyFontSize: 16,
                        mode: 'label',
                        custom: function (tooltip) {
                            if (!tooltip) return;
                            // disable displaying the color box;
                            tooltip.displayColors = false;
                        },
                        callbacks: {
                            title: function () {
                            },
                            label: function (item, data) {

                                var obj = data.datasets[item.datasetIndex].ttps[item.index];

                                var text = [];
                                text.push(obj.date);
                                text.push('Average Toll Cost: $' + obj.avg);
                                text.push('High Account: $' + obj.max);
                                text.push('Low Account: $' + obj.min);

                                return text;

                            }
                        }
                    },
                }
            });

            this.setState({
                    myChartRefAverageTollTransactionCost: myChartRefAcc,
                    currentAverageTollTransactionCost: this.state.dataAverageTollTransactionCost.daily
                }, () => {

                    this.buildChartAverageTollTransactionCost(this.state.myChartRefAverageTollTransactionCost, this.state.currentAverageTollTransactionCost);

                }
            );

        }

    };

    buildChartAverageTollTransactionCost(chart, current) {

        chart.data.labels = current.label;
        chart.data.datasets = [
            {
                label: "Average Toll Transaction Cost",
                data: current.data,
                ttps: current.tooltip,
                borderColor: '#008299',
                backgroundColor: '#008299',
                fill: false,
                hiddenLegend: true,
                datalabels: {
                    color: 'transparent'
                }
            }
        ];
        chart.update();

    };

    __fetchDataTotalPaymentGateway = () => {

        if (this.chartRefTotalPaymentGateway.current) {
            let myChartRef = this.chartRefTotalPaymentGateway.current.getContext("2d");

            let myChartRefAcc = new Chart(myChartRef, {
                type: 'bar',
                data: {
                    //Bring in data
                    labels: '',
                    datasets: [],
                    ttps: {}
                },
                options: {
                    maintainAspectRatio: false,
                    animation: {
                        duration: 10,
                    },
                    scales: {
                        xAxes: [{
                            gridLines: {
                                display: false,
                            },
                        }],
                        yAxes: [{
                            showLines: true,
                            gridLines: {
                                display: true,
                            },
                            ticks: {
                                precision: 0,
                                beginAtZero: true,
                            }
                        }]
                    },
                    legend: {display: false},
                }
            });

            this.setState({
                    myChartRefTotalPaymentGateway: myChartRefAcc,
                    currentTotalPaymentGateway: this.state.dataTotalPaymentGateway.daily
                }, () => {

                    this.buildChartTotalPaymentGateway(this.state.myChartRefTotalPaymentGateway, this.state.currentTotalPaymentGateway);

                }
            );

        }

    };

    buildChartTotalPaymentGateway(chart, current) {

        chart.data.labels = current.label;
        chart.data.datasets = [
            {
                label: "Total Payment Gateway Transaction Count",
                data: current.data,
                ttps: current.tooltip,
                borderColor: '#008299',
                backgroundColor: '#008299',
                fill: false,
                hiddenLegend: true,
                datalabels: {
                    color: 'transparent'
                }
            }
        ];
        chart.update();

    };

    __fetchDataTollActivity = () => {

        if (this.chartRefTollActivity.current) {
            let myChartRef = this.chartRefTollActivity.current.getContext("2d");

            let myChartRefAcc = new Chart(myChartRef, {
                type: 'bar',
                data: {
                    //Bring in data
                    labels: '',
                    datasets: [],
                    ttps: {}
                },
                options: {
                    maintainAspectRatio: false,
                    animation: {
                        duration: 10,
                    },
                    scales: {
                        xAxes: [{
                            gridLines: {
                                display: false,
                            },
                        }],
                        yAxes: [{
                            showLines: true,
                            gridLines: {
                                display: true,
                            },
                            ticks: {
                                precision: 0,
                                beginAtZero: true,
                            }
                        }]
                    },
                    legend: {display: false},
                }
            });

            this.setState({
                    myChartRefTollActivity: myChartRefAcc,
                    currentTollActivity: this.state.dataTollActivity.daily
                }, () => {

                    this.buildChartTollActivity(this.state.myChartRefTollActivity, this.state.currentTollActivity);

                }
            );

        }

    };

    buildChartTollActivity(chart, current) {

        chart.data.labels = current.label;
        chart.data.datasets = [
            {
                label: "Toll Activity",
                data: current.data,
                ttps: current.tooltip,
                borderColor: '#008299',
                backgroundColor: '#008299',
                fill: false,
                hiddenLegend: true,
                datalabels: {
                    color: 'transparent'
                }
            }
        ];
        chart.update();

    };

    buildChartPrepayAmount(chart, dataStackedBar) {

        let innerWidth = window.innerWidth;

        let arrTotal = dataStackedBar.dates.map(x => 0);

        let tenth = parseInt(_.max(dataStackedBar.sum) * 0.1) + 1;
        let twentieth = parseInt(_.max(dataStackedBar.sum) * 0.2) - 1;

        if (twentieth === -1) {
            twentieth = 0;
        }

        let numPadding = _.max(dataStackedBar.sum) + tenth;

        chart.data.labels = dataStackedBar.dates;
        chart.data.datasets = [
            {
                label: dataStackedBar.Phone.label,
                data: dataStackedBar.Phone.value,
                backgroundColor: '#5be8e4',
                borderColor: borderColor,
                borderWidth: borderWidth,
                barPercentage: barPercentage,
                stack: 'Stack 0',
                datalabels: {
                    align: 'center',
                    anchor: 'center',
                    color: 'white',
                    display: function (context) {
                        return context.dataset.data[context.dataIndex] > twentieth;
                    },
                    font: {
                        weight: 'normal'
                    },
                    formatter: Math.round
                }
            },
            {
                label: dataStackedBar.Email.label,
                data: dataStackedBar.Email.value,
                backgroundColor: '#61c8d4',
                borderColor: borderColor,
                borderWidth: borderWidth,
                barPercentage: barPercentage,
                stack: 'Stack 0',
                datalabels: {
                    align: 'center',
                    anchor: 'center',
                    color: 'white',
                    display: function (context) {
                        return context.dataset.data[context.dataIndex] > twentieth;
                    },
                    font: {
                        weight: 'normal'
                    },
                    formatter: Math.round
                }
            },
            {
                label: dataStackedBar.Google.label,
                data: dataStackedBar.Google.value,
                backgroundColor: '#7bbee3',
                borderColor: borderColor,
                borderWidth: borderWidth,
                barPercentage: barPercentage,
                stack: 'Stack 0',
                datalabels: {
                    align: 'center',
                    anchor: 'center',
                    color: 'white',
                    display: function (context) {
                        return context.dataset.data[context.dataIndex] > twentieth;
                    },
                    font: {
                        weight: 'normal'
                    },
                    formatter: Math.round
                }
            },
            {
                label: dataStackedBar.Facebook.label,
                data: dataStackedBar.Facebook.value,
                backgroundColor: '#7ba0cf',
                borderColor: borderColor,
                borderWidth: borderWidth,
                barPercentage: barPercentage,
                stack: 'Stack 0',
                datalabels: {
                    align: 'center',
                    anchor: 'center',
                    color: 'white',
                    display: function (context) {
                        return context.dataset.data[context.dataIndex] > twentieth;
                    },
                    font: {
                        weight: 'normal'
                    },
                    formatter: Math.round
                }
            },
            {
                label: "Total",
                data: arrTotal,
                backgroundColor: 'transparent',
                stack: 'Stack 0',
                hiddenLegend: true,
            },
        ];
        chart.options = {
            maintainAspectRatio: false,
            legend: {
                position: innerWidth < 1200 ? 'bottom' : 'right',
                align: 'center',
                fullWidth: true,
                reverse: true,
                labels: {
                    boxWidth: 30,
                    padding: 10,
                    fontSize: innerWidth < 1200 ? 14 : 16,
                    fontFamily: 'Roboto Light',
                    filter: function (item, data) {
                        return (!data.datasets[item.datasetIndex].hiddenLegend)
                    }
                }
            },
            animation: {
                duration: 10,
            },
            tooltips: {
                mode: 'label',
                callbacks: {
                    label: function (item, data) {
                        if (!data.datasets[item.datasetIndex].hiddenLegend) {
                            return data.datasets[item.datasetIndex].label + ": " + numberWithCommas(item.yLabel);
                        }
                    }
                }
            },
            scales: {
                xAxes: [{
                    stacked: true,
                    gridLines: {
                        display: false,
                    }
                }],
                yAxes: [{
                    stacked: true,
                    gridLines: {
                        display: false,
                    },
                    ticks: {
                        maxTicksLimit: 2,
                        max: numPadding
                    },
                }]
            },
            plugins: {
                datalabels: {
                    formatter: (value, ctx) => {
                        return parseFloat(ctx.chart.$totalizer.stacktotal[ctx.dataset.stack][ctx.dataIndex]).toFixed(2);
                    },
                    align: 'end',
                    anchor: 'end',
                    display: function (ctx) {
                        return ctx.chart.$totalizer.utmost[ctx.dataset.stack] === ctx.datasetIndex;
                    },
                    color: '#000000',
                    font: {
                        size: 17,
                        weight: 400
                    }
                }
            }
        };
        chart.update();

    };

    buildChartTollTransactionType(chart, current) {

        chart.data.labels = current.label;
        chart.data.datasets = [
            {
                label: "Business",
                data: Array.isArray(current) ? current : current.Business.data,
                borderColor: '#ffb347',
                backgroundColor: '#ffb347',
                hiddenLegend: true,
                datalabels: {
                    color: 'transparent'
                }
            },
            {
                label: "Personal",
                data: Array.isArray(current) ? current : current.Personal.data,
                borderColor: '#779ecb',
                backgroundColor: '#779ecb',
                hiddenLegend: true,
                datalabels: {
                    color: 'transparent'
                }
            }
        ];
        chart.update();

    };

    handleChange = (target, prop) => {
        this.setState({
            [prop]: target
        });
    };

    handleChangePeriodCreation = (target) => {
        this.setState({
            periodCreation: target
        });
    };

    render() {

        return (
            <div>
                <div className="col-12" style={{padding:'0px'}}>
                    <div className="row" >
                        <div className="col-md-6 col-sm-12" style={{marginTop:'20px'}}>
                            <div className=" card" >
                                <h2>Average Dollar Amount Per Account</h2>
                                <div className="d-md-flex justify-content-center mt-4 mb-4">
                                    <nav className="nav nav-filter">
                                        <button
                                            className={"nav-link pl-0 " + (this.state.periodAverageDollarSummary === 'daily' && 'active')}
                                            onClick={() => this.handleChange('daily', 'periodAverageDollarSummary')}>Daily
                                        </button>
                                        <button
                                            className={"nav-link " + (this.state.periodAverageDollarSummary === 'weekly' && 'active')}
                                            onClick={() => this.handleChange('weekly', 'periodAverageDollarSummary')}>Weekly
                                        </button>
                                        <button
                                            className={"nav-link " + (this.state.periodAverageDollarSummary === 'monthly' && 'active')}
                                            onClick={() => this.handleChange('monthly', 'periodAverageDollarSummary')}>Monthly
                                        </button>
                                    </nav>
                                </div>
                                <div className="chart-container">
                                    <canvas
                                        id="myChartAverageDollarSummary"
                                        ref={this.chartRefAverageDollarSummary}
                                    />
                                </div>
                            </div>
                        </div>
                        <div className="col-md-6 col-sm-12" style={{marginTop:'20px'}}>
                            <div className=" card">
                                <h2>Average Toll use Per Account</h2>
                                <div className="d-md-flex justify-content-center mt-4 mb-4">
                                    <nav className="nav nav-filter">
                                        <button
                                            className={"nav-link pl-0 " + (this.state.periodAverageTollAccount === 'daily' && 'active')}
                                            onClick={() => this.handleChange('daily', 'periodAverageTollAccount')}>Daily
                                        </button>
                                        <button
                                            className={"nav-link " + (this.state.periodAverageTollAccount === 'weekly' && 'active')}
                                            onClick={() => this.handleChange('weekly', 'periodAverageTollAccount')}>Weekly
                                        </button>
                                        <button
                                            className={"nav-link " + (this.state.periodAverageTollAccount === 'monthly' && 'active')}
                                            onClick={() => this.handleChange('monthly', 'periodAverageTollAccount')}>Monthly
                                        </button>
                                    </nav>
                                </div>
                                <div className="chart-container">
                                    <canvas
                                        id="myChartAverageTollAccount"
                                        ref={this.chartRefAverageTollAccount}
                                    />
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-md-6 col-sm-12" style={{marginTop:'20px'}}>
                            <div className="card" >
                                <h2>Average Pre Pay Account Value</h2>
                                <div className="d-md-flex justify-content-center mt-4 mb-4">
                                    <nav className="nav nav-filter">
                                        <button
                                            className={"nav-link pl-0 " + (this.state.periodPrepayAmount === 'daily' && 'active')}
                                            onClick={() => this.handleChange('daily', 'periodPrepayAmount')}>Daily
                                        </button>
                                        <button
                                            className={"nav-link " + (this.state.periodPrepayAmount === 'weekly' && 'active')}
                                            onClick={() => this.handleChange('weekly', 'periodPrepayAmount')}>Weekly
                                        </button>
                                        <button
                                            className={"nav-link " + (this.state.periodPrepayAmount === 'monthly' && 'active')}
                                            onClick={() => this.handleChange('monthly', 'periodPrepayAmount')}>Monthly
                                        </button>
                                    </nav>
                                </div>
                                <div className="chart-container">
                                    <canvas
                                        id="myChartPrepayAmount"
                                        ref={this.chartRefPrepayAmount}
                                    />
                                </div>
                            </div>
                        </div>
                        {/*<h2>Toll Activity</h2>
                        <div className="d-md-flex justify-content-center mt-4 mb-4">
                            <nav className="nav nav-filter">
                                <button className={"nav-link pl-0 "+ (this.state.periodTollActivity === 'daily' && 'active')} onClick={()=>this.handleChange('daily','periodTollActivity')}>Daily</button>
                                <button className={"nav-link "+ (this.state.periodTollActivity === 'weekly' && 'active')} onClick={()=>this.handleChange('weekly','periodTollActivity')}>Weekly</button>
                                <button className={"nav-link "+ (this.state.periodTollActivity === 'monthly' && 'active')} onClick={()=>this.handleChange('monthly','periodTollActivity')}>Monthly</button>
                            </nav>
                        </div>

                        <div className="chart-container">
                            <canvas
                                id="myChartTollActivity"
                                ref={this.chartRefTollActivity}
                            />
                        </>*/}
                         <div className="col-md-6 col-sm-12" style={{marginTop:'20px'}}>
                            <div className="card">
                                <h2>Total Payment Gateway Transaction Count</h2>
                                <div className="d-md-flex justify-content-center mt-4 mb-4">
                                    <nav className="nav nav-filter">
                                        <button
                                            className={"nav-link pl-0 " + (this.state.periodTotalPaymentGateway === 'daily' && 'active')}
                                            onClick={() => this.handleChange('daily', 'periodTotalPaymentGateway')}>Daily
                                        </button>
                                        <button
                                            className={"nav-link " + (this.state.periodTotalPaymentGateway === 'weekly' && 'active')}
                                            onClick={() => this.handleChange('weekly', 'periodTotalPaymentGateway')}>Weekly
                                        </button>
                                        <button
                                            className={"nav-link " + (this.state.periodTotalPaymentGateway === 'monthly' && 'active')}
                                            onClick={() => this.handleChange('monthly', 'periodTotalPaymentGateway')}>Monthly
                                        </button>
                                    </nav>
                                </div>
                                <div className="chart-container">
                                    <canvas
                                        id="myChartTotalPaymentGateway"
                                        ref={this.chartRefTotalPaymentGateway}
                                    />
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className='row'>
                        <div className="col-md-6 col-sm-12" style={{marginTop:'20px'}}>
                            <div className=" card" >
                                <h2>Total Payment Transaction</h2>
                                <div className="d-md-flex justify-content-center mt-4 mb-4">
                                    <nav className="nav nav-filter">
                                        <button
                                            className={"nav-link pl-0 " + (this.state.periodTotalPaymentTransaction === 'daily' && 'active')}
                                            onClick={() => this.handleChange('daily', 'periodTotalPaymentTransaction')}>Daily
                                        </button>
                                        <button
                                            className={"nav-link " + (this.state.periodTotalPaymentTransaction === 'weekly' && 'active')}
                                            onClick={() => this.handleChange('weekly', 'periodTotalPaymentTransaction')}>Weekly
                                        </button>
                                        <button
                                            className={"nav-link " + (this.state.periodTotalPaymentTransaction === 'monthly' && 'active')}
                                            onClick={() => this.handleChange('monthly', 'periodTotalPaymentTransaction')}>Monthly
                                        </button>
                                    </nav>
                                </div>
                                <div className="chart-container">
                                    <canvas
                                        id="myChartTotalPaymentTransaction"
                                        ref={this.chartRefTotalPaymentTransaction}
                                    />
                                </div>
                            </div>
                        </div>
                        <div className="col-md-6 col-sm-12" style={{marginTop:'20px'}}>
                            <div className="card">
                                <h2>Total Payment Volume</h2>
                                <div className="d-md-flex justify-content-center mt-4 mb-4">
                                    <nav className="nav nav-filter">
                                        <button
                                            className={"nav-link pl-0 " + (this.state.periodTotalPaymentVolume === 'daily' && 'active')}
                                            onClick={() => this.handleChange('daily', 'periodTotalPaymentVolume')}>Daily
                                        </button>
                                        <button
                                            className={"nav-link " + (this.state.periodTotalPaymentVolume === 'weekly' && 'active')}
                                            onClick={() => this.handleChange('weekly', 'periodTotalPaymentVolume')}>Weekly
                                        </button>
                                        <button
                                            className={"nav-link " + (this.state.periodTotalPaymentVolume === 'monthly' && 'active')}
                                            onClick={() => this.handleChange('monthly', 'periodTotalPaymentVolume')}>Monthly
                                        </button>
                                    </nav>
                                </div>
                                <div className="chart-container">
                                    <canvas
                                        id="myChartTotalPaymentVolume"
                                        ref={this.chartRefTotalPaymentVolume}
                                    />
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className='row'>
                        <div className="col-md-6 col-sm-12" style={{marginTop:'20px'}}>
                            <ChartPaymentsStatus/>
                        </div>
                        <div className="col-md-6 col-sm-12" style={{marginTop:'20px'}}>
                            <div className="card">
                                <h2>Average Toll Transaction Cost</h2>
                                <div className="d-md-flex justify-content-center mt-4 mb-4">
                                    <nav className="nav nav-filter">
                                        <button
                                            className={"nav-link pl-0 " + (this.state.periodAverageTollTransactionCost === 'daily' && 'active')}
                                            onClick={() => this.handleChange('daily', 'periodAverageTollTransactionCost')}>Daily
                                        </button>
                                        <button
                                            className={"nav-link " + (this.state.periodAverageTollTransactionCost === 'weekly' && 'active')}
                                            onClick={() => this.handleChange('weekly', 'periodAverageTollTransactionCost')}>Weekly
                                        </button>
                                        <button
                                            className={"nav-link " + (this.state.periodAverageTollTransactionCost === 'monthly' && 'active')}
                                            onClick={() => this.handleChange('monthly', 'periodAverageTollTransactionCost')}>Monthly
                                        </button>
                                    </nav>
                                </div>
                                <div className="chart-container">
                                    <canvas
                                        id="myChartAverageTollTransactionCost"
                                        ref={this.chartRefAverageTollTransactionCost}
                                    />
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className='row'>
                        <div className="col-md-6 col-sm-12" style={{marginTop:'20px'}}>
                            <div className="card" >
                                <div className='row'>
                                    <div className="col-md-8 col-lg-7">
                                        <h2>Onboard Completion</h2>
                                        <div className="chart-container onboard" style={{marginTop:'40px'}}>
                                            <canvas
                                                id="myChartProgress"
                                                ref={this.chartRefProgress}
                                            />
                                        </div>
                                    </div>
                                    <div className="col-md-4 col-lg-5 px-md-0">
                                        <h2 className="d-none d-md-block">&nbsp;</h2>
                                        <div className="text-center">
                                            {
                                                this.state.onBoardingAvgTimeD ? (
                                                    <h4 className="number-big mb-0">{this.state.onBoardingAvgTimeD}<sup>d {this.state.onBoardingAvgTimeH}h {this.state.onBoardingAvgTimeM}min</sup>
                                                    </h4>
                                                ) : (
                                                    <h4 className="number-big mb-0">{this.state.onBoardingAvgTimeH}<sup>h {this.state.onBoardingAvgTimeM}min</sup>
                                                    </h4>
                                                )
                                            }
                                            <p>Average time</p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="col-md-6 col-sm-12" style={{marginTop:'20px'}}>
                            <div className="card" >
                                <h2>Account Creation Method</h2>
                                <div className="d-md-flex justify-content-center">
                                    <nav className="nav nav-filter justify-content-center">
                                        <button
                                            className={"nav-link pl-0 " + (this.state.periodCreation === 'daily' && 'active')}
                                            onClick={() => this.handleChangePeriodCreation('daily')}>Daily
                                        </button>
                                        <button className={"nav-link " + (this.state.periodCreation === 'weekly' && 'active')}
                                                onClick={() => this.handleChangePeriodCreation('weekly')}
                                                disabled={this.state.disableWeekly}>Weekly
                                        </button>
                                        <button className={"nav-link " + (this.state.periodCreation === 'monthly' && 'active')}
                                                onClick={() => this.handleChangePeriodCreation('monthly')}
                                                disabled={this.state.disableMonthly}>Monthly
                                        </button>
                                    </nav>
                                </div>
                                <div className="chart-container">
                                    <canvas
                                        id="myChartBar"
                                        ref={this.chartRefBar}
                                    />
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className='row'>
                        <div className="col-md-6 col-sm-12" style={{marginTop:'20px'}}>
                            <div className="card" >
                                <h2>Toll Transaction: Business vs. Personal</h2>
                                <div className="d-md-flex justify-content-center mt-4 mb-4">
                                    <nav className="nav nav-filter">
                                        <button
                                            className={"nav-link pl-0 " + (this.state.periodTollTransactionType === 'daily' && 'active')}
                                            onClick={() => this.handleChange('daily', 'periodTollTransactionType')}>Daily
                                        </button>
                                        <button
                                            className={"nav-link " + (this.state.periodTollTransactionType === 'weekly' && 'active')}
                                            onClick={() => this.handleChange('weekly', 'periodTollTransactionType')}>Weekly
                                        </button>
                                        <button
                                            className={"nav-link " + (this.state.periodTollTransactionType === 'monthly' && 'active')}
                                            onClick={() => this.handleChange('monthly', 'periodTollTransactionType')}>Monthly
                                        </button>
                                    </nav>
                                </div>
                                <div className="chart-container">
                                    <canvas
                                        id="myChartAverageTollTransactionCost"
                                        ref={this.chartRefTollTransactionType}
                                    />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

        )

    }
}

export default ChartOld;
