import React, {Component} from "react";
import {KeyboardDatePicker, MuiPickersUtilsProvider} from "@material-ui/pickers";
import MomentUtils from "@date-io/moment";
import moment from "moment";
import CsvDownload from "react-csv-downloader";
import Api from "../../../libraries/api";
import CircularProgress from "@material-ui/core/CircularProgress";
import TableBody from "@material-ui/core/TableBody";
import TableRow from "@material-ui/core/TableRow";
import TableCell from "@material-ui/core/TableCell";
import TableHead from "@material-ui/core/TableHead";
import Table from "@material-ui/core/Table";

class UsersSummaryReport extends Component {
    constructor(props) {
        super(props);
        let today = new Date()
        this.state = {
            start_date: moment(today).format("YYYY-MM-DD"),
            end_date: moment(today).format("YYYY-MM-DD"),
            showSummary: false,
            showExport: false,
            loading: false,
            summary: '',
            header: '',
            content: ''
        }
        this.handleChangeDate = this.handleChangeDate.bind(this);
        this.searchReportSummary = this.searchReportSummary.bind(this);
    }

    handleChangeDate(e, prop) {
        if (prop === "start_date") {
            this.setState({
                "start_date": moment(e).format("YYYY-MM-DD"),
                showSummary: false,
                showExport: false,
                summary: '',
                header: '',
                content: ''
            })
        }
        if (prop === "end_date") {
            this.setState({
                "end_date": moment(e).format("YYYY-MM-DD"),
                showSummary: false,
                showExport: false,
                summary: '',
                header: '',
                content: ''
            })
        }

    };

    searchReportSummary() {
        let startDate = moment(this.state.start_date).format("YYYY-MM-DD 00:00:00")
        let endDate = moment(this.state.end_date).format("YYYY-MM-DD 23:59:59")
        let params = "?registerDateStartAt=" + startDate + "&registerDateEndAt=" + endDate
        let url = "/reports/users-activity-csv" + params
        this.setState({
            loading: true,
        }, () => {
            Api.get(url, [], false).then((resp) => {
                let summary = resp["data"]["summary"]
                let header = resp["data"]["headers"]
                let content = resp["data"]["content"]
                this.setState({
                    showSummary: true,
                    showExport: true,
                    loading: false,
                    summary: summary,
                    header: header,
                    content: content
                })
            }).catch(err => {
                console.log(err);
            });
        });
    }

    render() {
        return (
            <div className="card" style={{marginLeft:'-20px',marginRight:'-20px'}}>
                <div className={"row"}>
                    <MuiPickersUtilsProvider utils={MomentUtils}>
                        <div className={"col-md-6"}>
                            <KeyboardDatePicker
                                variant="inline"
                                margin="normal"
                                id={"start_date_user_report"}
                                label="Start Date"
                                format={"MM/DD/YYYY"}
                                value={this.state.start_date}
                                onChange={(startDate) => this.handleChangeDate(startDate, 'start_date')}
                                KeyboardButtonProps={{
                                    'aria-label': 'change date',
                                }}
                            />
                        </div>
                        <div className={"col-md-6"}>
                            <KeyboardDatePicker
                                variant="inline"
                                margin="normal"
                                id={"end_date_user_report"}
                                label="End Date"
                                format={"MM/DD/YYYY"}
                                value={this.state.end_date}
                                onChange={(endDate) => this.handleChangeDate(endDate, 'end_date')}
                                KeyboardButtonProps={{
                                    'aria-label': 'change date',
                                }}
                            />
                        </div>
                    </MuiPickersUtilsProvider>
                </div>
                <div className={"row"}>
                    <div className={"col-md-2"}>
                        {this.state.loading ?
                            (<CircularProgress color="primary"/>) :
                            ('')
                        }
                    </div>
                </div>
                <div className={"row"}>
                    <div className={"col-md-2"}>
                        <button className={"btn btn-blue"} onClick={this.searchReportSummary}>Search</button>
                    </div>
                    <div className={"col-md-2"}>
                        <Export
                            showExport={this.state.showExport}
                            headers={this.state.header}
                            content={this.state.content}
                        />
                    </div>
                </div>
                <div className={"row"}>
                    <Summary
                        showSummary={this.state.showSummary}
                        summary={this.state.summary}
                    />
                </div>
            </div>
        )
    }
}

class Export extends Component {
    render() {
        let showExport = this.props.showExport
        let headers = this.props.headers
        let content = this.props.content
        if (showExport === true) {
            return <CsvDownload columns={headers} datas={content} filename="UsersSummary">
                <button className={"btn btn-blue"}>Download</button>
            </CsvDownload>
        }
        return false
    }
}

class Summary extends Component {

    render() {
        let showSummary = this.props.showSummary
        let summary = this.props.summary
        if (showSummary === true) {
            return (
                <Table className="table-list mt-3" size="small">
                    <TableHead>
                        <TableRow>
                            <TableCell>Concept</TableCell>
                            <TableCell>Value</TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {Object.keys(summary).map(function (item, i) {
                            return (
                                <TableRow key={i}>
                                    <TableCell>{item}</TableCell>
                                    <TableCell>{summary[item]}</TableCell>
                                </TableRow>
                            )
                        })}
                    </TableBody>
                </Table>
            )
        }
        return false
    }
}

export default UsersSummaryReport