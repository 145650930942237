import React, {Component} from "react";
import AppBar from "@material-ui/core/AppBar";
import Tabs from "@material-ui/core/Tabs";
import Tab from "@material-ui/core/Tab";
import Typography from "@material-ui/core/Typography";
import Box from "@material-ui/core/Box";
import FeeRules from "./fee-rules";
import TransRules from "./trans-rules";

function a11yProps(index) {
    return {
        id: `scrollable-auto-tab-${index}`,
        "aria-controls": `scrollable-auto-tabpanel-${index}`,
    };
}

function TabPanel(props) {
    const {children, value, index, ...other} = props;

    return (
        <Typography
            component="div"
            role="tabpanel"
            hidden={value !== index}
            id={`scrollable-auto-tabpanel-${index}`}
            aria-labelledby={`scrollable-auto-tab-${index}`}
            {...other}
        >
            <Box p={3}>{children}</Box>
        </Typography>
    );
}

class PaymentRules extends Component {

    constructor(props) {
        super(props);
        this.state = {
            showFee: true,
            showTrans: false,
            activeTab: 0,
        };
        this.handleTabChange = this.handleTabChange.bind(this);
    }

    handleTabChange(e, newValue) {
        let fee = false;
        let trans = false;
        if (newValue === 0) {
            fee = true;
            trans = false;
        }
        if (newValue === 1) {
            fee = false;
            trans = true;
        }
        this.setState({
            activeTab: newValue,
            showFee: fee,
            showTrans: trans,
        });
    }

    render() {
        return (
            <div>
                <div>
                    <div className="">
                        <div  style={{padding:'0px',marginLeft:'-20px',marginRight:'-20px'}}>
                            <AppBar position="static" color="default">
                                <div className="row align-items-center">
                                    <div className="col-md-12">
                                        <h1 className="text-title-big">Payment amounts</h1>
                                        <p className="mb-0">
                                            Manage the charge fee for many products, transactions or operations.
                                        </p>
                                    </div>
                                </div>

                                <Tabs
                                    value={this.state.activeTab}
                                    onChange={this.handleTabChange}
                                    indicatorColor="primary"
                                    textColor="primary"
                                    variant="scrollable"
                                    scrollButtons="auto"
                                    aria-label="scrollable auto tabs example"
                                >
                                    <Tab label="Fee" {...a11yProps(0)} />
                                    <Tab label="Transaction limit" {...a11yProps(1)} />
                                </Tabs>
                            </AppBar>
                            <TabPanel value={this.state.activeTab} index={0}>
                                {(this.state.showFee === true) ? (<FeeRules/>) : ('')}
                            </TabPanel>
                            <TabPanel value={this.state.activeTab} index={1}>
                                {(this.state.showTrans === true) ? (<TransRules/>) : ('')}
                            </TabPanel>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}

export default PaymentRules;