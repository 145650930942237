import { CircularProgress, Table, TableBody, TableCell, TableHead, TablePagination, TableRow } from '@material-ui/core'
import React, { Component } from 'react'
import Api from '../../../libraries/api'
//import DateFormat from '../../../libraries/data-format-helper'
import { history } from '../../../shared/configure-store'
import TablePaginationActions from '../../presentational/table-pagination-actions'

export default class PaymentLog extends Component {

  constructor(props){
    super(props)
    this.state={
      rows:[],
      loading:true,
      total: 0,
      perPage: 50,
      currentPage: 0,
      currentPageTable: 0,
      totalData: 0,
    }
    this.getPaymentLogList()
  }

  getPaymentLogList(update){
    let page = update ? parseInt(this.state.currentPage + 1) : this.state.currentPage;
    Api.get("/top-up/payment-intent-error?limit="+this.state.perPage+'&page='+page).then(resp=>{
      //console.log("resp",resp)
      this.setState({
        loading:false,
        rows:resp.data.rows,
        total: resp.data.total_rows,
        perPage: this.state.perPage,
        currentPage: resp.data.page,
        currentPageTable: resp.data.page - 1,
        totalData: resp.data.total_rows,
      })
    }).catch(err=>{
      this.setState({loading:false})
      console.log("error",err)
    })
  }

  handleChangePage = (event, newPage) => {
    this.setState({
        rows: [],
        currentPage: newPage,
        currentPageTable: newPage,
        loading: true,
    }, () => {
        this.getPaymentLogList(true);
    });
};

handleChangeRowsPerPage = event => {
    this.setState({
        perPage: event.target.value,
        currentPage: 0,
        currentPageTable: 0,
        loading: true,
    }, () => {
        this.getPaymentLogList(false);
    });
};

  getDate(json){
    var jSon = JSON.parse(json)
    let date = jSon.payment_method === undefined ? '' : new Date(jSon.payment_method.created*1000)
    let str = date === ''? '': date.toUTCString()
    //let dateUTC = Date.parse(str)
    //let dateStr = dateUTC === "" ? '' : DateFormat.fullDateTime(dateUTC)
    return str
  }

  getPaymentMethod(json){
    var jSon = JSON.parse(json)
    let brand = jSon.payment_method === undefined ? '': jSon.payment_method.card.brand
    let number = jSon.payment_method === undefined ? '': '*'+ jSon.payment_method.card.last4
    return brand +' '+ number 
  }
  getDescription(json){
    var jSon = JSON.parse(json)
    return jSon.message
  }

  render() {
    return (
      <div >
        <h2 className='text-title-big'>Transactions declined</h2>
        <div className='card' style={{marginTop:'20px'}}>
        <div className="table-wrapper">
          <Table className="table-list mt-3" size="small">
              <TableHead>
                  <TableRow>
                    <TableCell>FullName</TableCell>
                    <TableCell>Payment Id</TableCell>
                    <TableCell>Payment Method</TableCell>
                    <TableCell>Amount</TableCell>
                    <TableCell>Date</TableCell>
                    <TableCell>Description</TableCell>
                  </TableRow>
              </TableHead>
              <TableBody>
                {this.state.loading ? (
                    <TableRow>
                        <TableCell colSpan={8} align="center" className="py-5">
                            <CircularProgress color="primary"/>
                        </TableCell>
                    </TableRow>
                ) : (
                    this.state.rows.length === 0 ? (
                        <TableRow style={{height: 53 * 10}}>
                            <TableCell colSpan={8} style={{textAlign: "center"}}>No transaction</TableCell>
                        </TableRow>
                    ) : (
                        this.state.rows.map((row, index) => {
                            return (
                                <TableRow
                                    key={index}
                                    style={{cursor:'pointer'}}
                                    onClick={() => history.push('/accounts/detail/' + row.userId)}
                                    selected={row.selected}
                                >
                                  <TableCell>{row.user.fullName}</TableCell>
                                  <TableCell>{row.paymentId}</TableCell>
                                  <TableCell>{this.getPaymentMethod(row.stripePaymentIntentError)}</TableCell>
                                  <TableCell>{row.amount === null ? 'NA' : parseFloat(row.amount).toFixed(2)}</TableCell>
                                  <TableCell>{this.getDate(row.stripePaymentIntentError)}</TableCell>
                                  <TableCell>{this.getDescription(row.stripePaymentIntentError)}</TableCell>
                                </TableRow>
                            )
                        })
                    )
                )}
              </TableBody>
          </Table>
        </div>
        <TablePagination
          rowsPerPageOptions={[50, 75, 100]}
          component="div"
          count={this.state.totalData}
          rowsPerPage={this.state.perPage}
          page={this.state.currentPageTable}
          backIconButtonProps={{
              'aria-label': 'previous page',
          }}
          nextIconButtonProps={{
              'aria-label': 'next page',
          }}
          onChangePage={this.handleChangePage}
          onChangeRowsPerPage={this.handleChangeRowsPerPage}
          ActionsComponent={TablePaginationActions}
        />
        </div>
      </div>
    )
  }
}
